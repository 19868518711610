.advantages {
    @include media-breakpoint-up(lg) {
        padding-top: rem(120px);
        padding-bottom: rem(185px);
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(60px);
        padding-bottom: rem(70px);
    }
    &__list {
        @include media-breakpoint-up(lg) {
            padding-top: rem(128px);
        }
        @include media-breakpoint-down(lg) {
            padding-top: rem(40px);
        }
    }
    &__box {
        background-color: #F3F4F6;
        @include media-breakpoint-up(xl) {
            min-height: rem(430px);
        }
        @include media-breakpoint-up(lg) {
            padding: rem(35px) rem(28px);
            .svg-icon {
                width: rem(90px);
                height: rem(90px);
            }            
        }
        @include media-breakpoint-down(lg) {
            padding: rem(25px);
            .svg-icon {
                width: rem(60px);
                height: rem(60px);
            }            
        }
    }
    &__description {
        @include media-breakpoint-up(lg) {
            margin-top: rem(45px);
        }
    }
}