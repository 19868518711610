.intro {
    background-color: var(--landing-bg-dark);
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        min-height: rem(763px);
    }
    &::after {
        @include pseudo;
        width: 150%;
        height: 200%;
        left: 0;
        top: 0;
        background: radial-gradient(closest-side, rgba(#AF1917, .66), transparent);
        transform: translate(20%, -25%);
    }
    &__title {
        @include font-size(52px);
        line-height: 1.4;
        font-weight: 800;
        letter-spacing: 1px
    }
    &__content {
        position: relative;
        z-index: get($index, default);
        @include media-breakpoint-up(md) {
            padding-top: rem(245px);
            padding-bottom: rem(145px);
        }
        @include media-breakpoint-down(md) {
            padding-top: rem(130px);
            padding-bottom: rem(140px);
        }
        .button {
            @include font-size(19px);
            font-weight: 700;
            border-radius: rem(10px);
            cursor: default;
            user-select: none;
            padding-left: rem(36px);
            padding-right: rem(36px);
            @include media-breakpoint-up(md) {
                margin-top: rem(85px);
            }
            @include media-breakpoint-down(md) {
                margin-top: rem(45px);
            }
        }
    }
}