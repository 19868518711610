@font-face {
  font-family: Gilroy;
  src: url("GilroyLight.246e9db3.woff2") format("woff2"), url("GilroyLight.1e75c432.woff") format("woff"), url("GilroyLight.7cdaa873.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroyRegular.0286a478.woff2") format("woff2"), url("GilroyRegular.5a2c5bf3.woff") format("woff"), url("GilroyRegular.504e7a08.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroyMedium.a730a6fc.woff2") format("woff2"), url("GilroyMedium.28784b57.woff") format("woff"), url("GilroyMedium.5c3b62d7.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroyMediumItalic.10525d75.woff2") format("woff2"), url("GilroyMediumItalic.789f157a.woff") format("woff"), url("GilroyMediumItalic.e128e353.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroySemiBold.99e93e92.woff2") format("woff2"), url("GilroySemiBold.bdd738c4.woff") format("woff"), url("GilroySemiBold.412aa1dd.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroyBold.633cd218.woff2") format("woff2"), url("GilroyBold.18109e61.woff") format("woff"), url("GilroyBold.0035d390.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url("GilroyHeavy.baa051c2.woff2") format("woff2"), url("GilroyHeavy.fe802693.woff") format("woff"), url("GilroyHeavy.b3ce4e80.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --landing-accent-color: #dc0c15;
  --landing-accent-color-rgb: 220, 12, 21;
  --landing-bg-dark: #151515;
  --landing-accent-color-darken: color-mix(in srgb, var(--landing-accent-color), #000 15%);
  --landing-section-offset-y: 5.625rem;
  --landing-underline-height: 6;
  --landing-underline-offset-y: -1px;
  --landing-color-gray: #b7b7b7;
  --landing-color-dark-gray: #666;
}

@media (width <= 1023.98px) {
  :root {
    --landing-section-offset-y: 3.125rem;
  }
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

:root {
  --page-breakpoint-xs: 0;
  --page-breakpoint-m: 375px;
  --page-breakpoint-sm: 576px;
  --page-breakpoint-md: 768px;
  --page-breakpoint-lg: 1024px;
  --page-breakpoint-xl: 1580px;
}

.row {
  --page-gutter-x: 1.5rem;
  --page-gutter-y: 0;
  margin-top: calc(-1 * var(--page-gutter-y));
  margin-right: calc(-.5 * var(--page-gutter-x));
  margin-left: calc(-.5 * var(--page-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--page-gutter-x) * .5);
  padding-left: calc(var(--page-gutter-x) * .5);
  margin-top: var(--page-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --page-gutter-x: 0;
}

.g-0, .gy-0 {
  --page-gutter-y: 0;
}

.g-1, .gx-1 {
  --page-gutter-x: .1875rem;
}

.g-1, .gy-1 {
  --page-gutter-y: .1875rem;
}

.g-2, .gx-2 {
  --page-gutter-x: .375rem;
}

.g-2, .gy-2 {
  --page-gutter-y: .375rem;
}

.g-3, .gx-3 {
  --page-gutter-x: .75rem;
}

.g-3, .gy-3 {
  --page-gutter-y: .75rem;
}

.g-4, .gx-4 {
  --page-gutter-x: 1.25025rem;
}

.g-4, .gy-4 {
  --page-gutter-y: 1.25025rem;
}

.g-5, .gx-5 {
  --page-gutter-x: 2.1rem;
}

.g-5, .gy-5 {
  --page-gutter-y: 2.1rem;
}

.g-6, .gx-6 {
  --page-gutter-x: 2.625rem;
}

.g-6, .gy-6 {
  --page-gutter-y: 2.625rem;
}

@media (width >= 375px) {
  .col-m {
    flex: 1 0;
  }

  .row-cols-m-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-m-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-m-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-m-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-m-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-m-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-m-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-m-auto {
    flex: none;
    width: auto;
  }

  .col-m-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-m-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-m-3 {
    flex: none;
    width: 25%;
  }

  .col-m-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-m-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-m-6 {
    flex: none;
    width: 50%;
  }

  .col-m-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-m-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-m-9 {
    flex: none;
    width: 75%;
  }

  .col-m-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-m-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-m-12 {
    flex: none;
    width: 100%;
  }

  .offset-m-0 {
    margin-left: 0;
  }

  .offset-m-1 {
    margin-left: 8.33333%;
  }

  .offset-m-2 {
    margin-left: 16.6667%;
  }

  .offset-m-3 {
    margin-left: 25%;
  }

  .offset-m-4 {
    margin-left: 33.3333%;
  }

  .offset-m-5 {
    margin-left: 41.6667%;
  }

  .offset-m-6 {
    margin-left: 50%;
  }

  .offset-m-7 {
    margin-left: 58.3333%;
  }

  .offset-m-8 {
    margin-left: 66.6667%;
  }

  .offset-m-9 {
    margin-left: 75%;
  }

  .offset-m-10 {
    margin-left: 83.3333%;
  }

  .offset-m-11 {
    margin-left: 91.6667%;
  }

  .g-m-0, .gx-m-0 {
    --page-gutter-x: 0;
  }

  .g-m-0, .gy-m-0 {
    --page-gutter-y: 0;
  }

  .g-m-1, .gx-m-1 {
    --page-gutter-x: .1875rem;
  }

  .g-m-1, .gy-m-1 {
    --page-gutter-y: .1875rem;
  }

  .g-m-2, .gx-m-2 {
    --page-gutter-x: .375rem;
  }

  .g-m-2, .gy-m-2 {
    --page-gutter-y: .375rem;
  }

  .g-m-3, .gx-m-3 {
    --page-gutter-x: .75rem;
  }

  .g-m-3, .gy-m-3 {
    --page-gutter-y: .75rem;
  }

  .g-m-4, .gx-m-4 {
    --page-gutter-x: 1.25025rem;
  }

  .g-m-4, .gy-m-4 {
    --page-gutter-y: 1.25025rem;
  }

  .g-m-5, .gx-m-5 {
    --page-gutter-x: 2.1rem;
  }

  .g-m-5, .gy-m-5 {
    --page-gutter-y: 2.1rem;
  }

  .g-m-6, .gx-m-6 {
    --page-gutter-x: 2.625rem;
  }

  .g-m-6, .gy-m-6 {
    --page-gutter-y: 2.625rem;
  }
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --page-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --page-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --page-gutter-x: .1875rem;
  }

  .g-sm-1, .gy-sm-1 {
    --page-gutter-y: .1875rem;
  }

  .g-sm-2, .gx-sm-2 {
    --page-gutter-x: .375rem;
  }

  .g-sm-2, .gy-sm-2 {
    --page-gutter-y: .375rem;
  }

  .g-sm-3, .gx-sm-3 {
    --page-gutter-x: .75rem;
  }

  .g-sm-3, .gy-sm-3 {
    --page-gutter-y: .75rem;
  }

  .g-sm-4, .gx-sm-4 {
    --page-gutter-x: 1.25025rem;
  }

  .g-sm-4, .gy-sm-4 {
    --page-gutter-y: 1.25025rem;
  }

  .g-sm-5, .gx-sm-5 {
    --page-gutter-x: 2.1rem;
  }

  .g-sm-5, .gy-sm-5 {
    --page-gutter-y: 2.1rem;
  }

  .g-sm-6, .gx-sm-6 {
    --page-gutter-x: 2.625rem;
  }

  .g-sm-6, .gy-sm-6 {
    --page-gutter-y: 2.625rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --page-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --page-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --page-gutter-x: .1875rem;
  }

  .g-md-1, .gy-md-1 {
    --page-gutter-y: .1875rem;
  }

  .g-md-2, .gx-md-2 {
    --page-gutter-x: .375rem;
  }

  .g-md-2, .gy-md-2 {
    --page-gutter-y: .375rem;
  }

  .g-md-3, .gx-md-3 {
    --page-gutter-x: .75rem;
  }

  .g-md-3, .gy-md-3 {
    --page-gutter-y: .75rem;
  }

  .g-md-4, .gx-md-4 {
    --page-gutter-x: 1.25025rem;
  }

  .g-md-4, .gy-md-4 {
    --page-gutter-y: 1.25025rem;
  }

  .g-md-5, .gx-md-5 {
    --page-gutter-x: 2.1rem;
  }

  .g-md-5, .gy-md-5 {
    --page-gutter-y: 2.1rem;
  }

  .g-md-6, .gx-md-6 {
    --page-gutter-x: 2.625rem;
  }

  .g-md-6, .gy-md-6 {
    --page-gutter-y: 2.625rem;
  }
}

@media (width >= 1024px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --page-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --page-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --page-gutter-x: .1875rem;
  }

  .g-lg-1, .gy-lg-1 {
    --page-gutter-y: .1875rem;
  }

  .g-lg-2, .gx-lg-2 {
    --page-gutter-x: .375rem;
  }

  .g-lg-2, .gy-lg-2 {
    --page-gutter-y: .375rem;
  }

  .g-lg-3, .gx-lg-3 {
    --page-gutter-x: .75rem;
  }

  .g-lg-3, .gy-lg-3 {
    --page-gutter-y: .75rem;
  }

  .g-lg-4, .gx-lg-4 {
    --page-gutter-x: 1.25025rem;
  }

  .g-lg-4, .gy-lg-4 {
    --page-gutter-y: 1.25025rem;
  }

  .g-lg-5, .gx-lg-5 {
    --page-gutter-x: 2.1rem;
  }

  .g-lg-5, .gy-lg-5 {
    --page-gutter-y: 2.1rem;
  }

  .g-lg-6, .gx-lg-6 {
    --page-gutter-x: 2.625rem;
  }

  .g-lg-6, .gy-lg-6 {
    --page-gutter-y: 2.625rem;
  }
}

@media (width >= 1580px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --page-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --page-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --page-gutter-x: .1875rem;
  }

  .g-xl-1, .gy-xl-1 {
    --page-gutter-y: .1875rem;
  }

  .g-xl-2, .gx-xl-2 {
    --page-gutter-x: .375rem;
  }

  .g-xl-2, .gy-xl-2 {
    --page-gutter-y: .375rem;
  }

  .g-xl-3, .gx-xl-3 {
    --page-gutter-x: .75rem;
  }

  .g-xl-3, .gy-xl-3 {
    --page-gutter-y: .75rem;
  }

  .g-xl-4, .gx-xl-4 {
    --page-gutter-x: 1.25025rem;
  }

  .g-xl-4, .gy-xl-4 {
    --page-gutter-y: 1.25025rem;
  }

  .g-xl-5, .gx-xl-5 {
    --page-gutter-x: 2.1rem;
  }

  .g-xl-5, .gy-xl-5 {
    --page-gutter-y: 2.1rem;
  }

  .g-xl-6, .gx-xl-6 {
    --page-gutter-x: 2.625rem;
  }

  .g-xl-6, .gy-xl-6 {
    --page-gutter-y: 2.625rem;
  }
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  --page-gutter-x: 1.5rem;
  --page-gutter-y: 0;
  padding-right: calc(var(--page-gutter-x) * .5);
  padding-left: calc(var(--page-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container-m, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container-m, .container {
    max-width: 720px;
  }
}

@media (width >= 1024px) {
  .container-lg, .container-md, .container-sm, .container-m, .container {
    max-width: 992px;
  }
}

@media (width >= 1580px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-m, .container {
    max-width: 1204px;
  }
}

.spinner-grow, .spinner-border {
  width: var(--page-spinner-width);
  height: var(--page-spinner-height);
  vertical-align: var(--page-spinner-vertical-align);
  animation: var(--page-spinner-animation-speed) linear infinite var(--page-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --page-spinner-width: 1.5em;
  --page-spinner-height: 1.5em;
  --page-spinner-vertical-align: -.125em;
  --page-spinner-border-width: .25em;
  --page-spinner-animation-speed: .75s;
  --page-spinner-animation-name: spinner-border;
  border: var(--page-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --page-spinner-width: 1rem;
  --page-spinner-height: 1rem;
  --page-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --page-spinner-width: 1.5em;
  --page-spinner-height: 1.5em;
  --page-spinner-vertical-align: -.125em;
  --page-spinner-animation-speed: .75s;
  --page-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --page-spinner-width: 1rem;
  --page-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --page-spinner-animation-speed: 1.5s;
  }
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--page-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --page-aspect-ratio: 100%;
}

.ratio-4x3 {
  --page-aspect-ratio: 75%;
}

.ratio-16x9 {
  --page-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --page-aspect-ratio: 42.8571%;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.focus-ring-primary {
  --page-focus-ring-color: rgba(var(--page-primary-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-secondary {
  --page-focus-ring-color: rgba(var(--page-secondary-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-success {
  --page-focus-ring-color: rgba(var(--page-success-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-info {
  --page-focus-ring-color: rgba(var(--page-info-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-warning {
  --page-focus-ring-color: rgba(var(--page-warning-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-danger {
  --page-focus-ring-color: rgba(var(--page-danger-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-light {
  --page-focus-ring-color: rgba(var(--page-light-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-dark {
  --page-focus-ring-color: rgba(var(--page-dark-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-black {
  --page-focus-ring-color: rgba(var(--page-black-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-white {
  --page-focus-ring-color: rgba(var(--page-white-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-gray {
  --page-focus-ring-color: rgba(var(--page-gray-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-silver {
  --page-focus-ring-color: rgba(var(--page-silver-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-smoky {
  --page-focus-ring-color: rgba(var(--page-smoky-rgb), var(--page-focus-ring-opacity));
}

.focus-ring-scarlet {
  --page-focus-ring-color: rgba(var(--page-scarlet-rgb), var(--page-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.border {
  border: var(--page-border-width) var(--page-border-style) var(--page-border-color);
}

.border-0 {
  border: 0;
}

.border-primary-subtle {
  border-color: var(--page-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--page-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--page-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--page-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--page-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--page-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--page-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--page-dark-border-subtle);
}

.border-opacity-10 {
  --page-border-opacity: .1;
}

.border-opacity-25 {
  --page-border-opacity: .25;
}

.border-opacity-50 {
  --page-border-opacity: .5;
}

.border-opacity-75 {
  --page-border-opacity: .75;
}

.border-opacity-100 {
  --page-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-25 {
  max-width: 25%;
}

.mw-50 {
  max-width: 50%;
}

.mw-75 {
  max-width: 75%;
}

.mw-100 {
  max-width: 100%;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.flex-fill {
  flex: auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .1875rem;
}

.m-2 {
  margin: .375rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1.25025rem;
}

.m-5 {
  margin: 2.1rem;
}

.m-6 {
  margin: 2.625rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .1875rem;
  margin-right: .1875rem;
}

.mx-2 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1.25025rem;
  margin-right: 1.25025rem;
}

.mx-5 {
  margin-left: 2.1rem;
  margin-right: 2.1rem;
}

.mx-6 {
  margin-left: 2.625rem;
  margin-right: 2.625rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .1875rem;
  margin-bottom: .1875rem;
}

.my-2 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1.25025rem;
  margin-bottom: 1.25025rem;
}

.my-5 {
  margin-top: 2.1rem;
  margin-bottom: 2.1rem;
}

.my-6 {
  margin-top: 2.625rem;
  margin-bottom: 2.625rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .1875rem;
}

.mt-2 {
  margin-top: .375rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1.25025rem;
}

.mt-5 {
  margin-top: 2.1rem;
}

.mt-6 {
  margin-top: 2.625rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: .1875rem;
}

.me-2 {
  margin-right: .375rem;
}

.me-3 {
  margin-right: .75rem;
}

.me-4 {
  margin-right: 1.25025rem;
}

.me-5 {
  margin-right: 2.1rem;
}

.me-6 {
  margin-right: 2.625rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .1875rem;
}

.mb-2 {
  margin-bottom: .375rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1.25025rem;
}

.mb-5 {
  margin-bottom: 2.1rem;
}

.mb-6 {
  margin-bottom: 2.625rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: .1875rem;
}

.ms-2 {
  margin-left: .375rem;
}

.ms-3 {
  margin-left: .75rem;
}

.ms-4 {
  margin-left: 1.25025rem;
}

.ms-5 {
  margin-left: 2.1rem;
}

.ms-6 {
  margin-left: 2.625rem;
}

.ms-auto {
  margin-left: auto;
}

.m-n1 {
  margin: -.1875rem;
}

.m-n2 {
  margin: -.375rem;
}

.m-n3 {
  margin: -.75rem;
}

.m-n4 {
  margin: -1.25025rem;
}

.m-n5 {
  margin: -2.1rem;
}

.m-n6 {
  margin: -2.625rem;
}

.mx-n1 {
  margin-left: -.1875rem;
  margin-right: -.1875rem;
}

.mx-n2 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.mx-n3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.mx-n4 {
  margin-left: -1.25025rem;
  margin-right: -1.25025rem;
}

.mx-n5 {
  margin-left: -2.1rem;
  margin-right: -2.1rem;
}

.mx-n6 {
  margin-left: -2.625rem;
  margin-right: -2.625rem;
}

.my-n1 {
  margin-top: -.1875rem;
  margin-bottom: -.1875rem;
}

.my-n2 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.my-n3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.my-n4 {
  margin-top: -1.25025rem;
  margin-bottom: -1.25025rem;
}

.my-n5 {
  margin-top: -2.1rem;
  margin-bottom: -2.1rem;
}

.my-n6 {
  margin-top: -2.625rem;
  margin-bottom: -2.625rem;
}

.mt-n1 {
  margin-top: -.1875rem;
}

.mt-n2 {
  margin-top: -.375rem;
}

.mt-n3 {
  margin-top: -.75rem;
}

.mt-n4 {
  margin-top: -1.25025rem;
}

.mt-n5 {
  margin-top: -2.1rem;
}

.mt-n6 {
  margin-top: -2.625rem;
}

.me-n1 {
  margin-right: -.1875rem;
}

.me-n2 {
  margin-right: -.375rem;
}

.me-n3 {
  margin-right: -.75rem;
}

.me-n4 {
  margin-right: -1.25025rem;
}

.me-n5 {
  margin-right: -2.1rem;
}

.me-n6 {
  margin-right: -2.625rem;
}

.mb-n1 {
  margin-bottom: -.1875rem;
}

.mb-n2 {
  margin-bottom: -.375rem;
}

.mb-n3 {
  margin-bottom: -.75rem;
}

.mb-n4 {
  margin-bottom: -1.25025rem;
}

.mb-n5 {
  margin-bottom: -2.1rem;
}

.mb-n6 {
  margin-bottom: -2.625rem;
}

.ms-n1 {
  margin-left: -.1875rem;
}

.ms-n2 {
  margin-left: -.375rem;
}

.ms-n3 {
  margin-left: -.75rem;
}

.ms-n4 {
  margin-left: -1.25025rem;
}

.ms-n5 {
  margin-left: -2.1rem;
}

.ms-n6 {
  margin-left: -2.625rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .1875rem;
}

.p-2 {
  padding: .375rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1.25025rem;
}

.p-5 {
  padding: 2.1rem;
}

.p-6 {
  padding: 2.625rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .1875rem;
  padding-right: .1875rem;
}

.px-2 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1.25025rem;
  padding-right: 1.25025rem;
}

.px-5 {
  padding-left: 2.1rem;
  padding-right: 2.1rem;
}

.px-6 {
  padding-left: 2.625rem;
  padding-right: 2.625rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .1875rem;
  padding-bottom: .1875rem;
}

.py-2 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1.25025rem;
  padding-bottom: 1.25025rem;
}

.py-5 {
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
}

.py-6 {
  padding-top: 2.625rem;
  padding-bottom: 2.625rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .1875rem;
}

.pt-2 {
  padding-top: .375rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1.25025rem;
}

.pt-5 {
  padding-top: 2.1rem;
}

.pt-6 {
  padding-top: 2.625rem;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: .1875rem;
}

.pe-2 {
  padding-right: .375rem;
}

.pe-3 {
  padding-right: .75rem;
}

.pe-4 {
  padding-right: 1.25025rem;
}

.pe-5 {
  padding-right: 2.1rem;
}

.pe-6 {
  padding-right: 2.625rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .1875rem;
}

.pb-2 {
  padding-bottom: .375rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1.25025rem;
}

.pb-5 {
  padding-bottom: 2.1rem;
}

.pb-6 {
  padding-bottom: 2.625rem;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: .1875rem;
}

.ps-2 {
  padding-left: .375rem;
}

.ps-3 {
  padding-left: .75rem;
}

.ps-4 {
  padding-left: 1.25025rem;
}

.ps-5 {
  padding-left: 2.1rem;
}

.ps-6 {
  padding-left: 2.625rem;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: .1875rem;
}

.row-gap-2 {
  row-gap: .375rem;
}

.row-gap-3 {
  row-gap: .75rem;
}

.row-gap-4 {
  row-gap: 1.25025rem;
}

.row-gap-5 {
  row-gap: 2.1rem;
}

.row-gap-6 {
  row-gap: 2.625rem;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: .1875rem;
}

.column-gap-2 {
  column-gap: .375rem;
}

.column-gap-3 {
  column-gap: .75rem;
}

.column-gap-4 {
  column-gap: 1.25025rem;
}

.column-gap-5 {
  column-gap: 2.1rem;
}

.column-gap-6 {
  column-gap: 2.625rem;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.15;
}

.lh-base {
  line-height: 1.35;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-primary {
  --page-text-opacity: 1;
  color: rgba(var(--page-primary-rgb), var(--page-text-opacity));
}

.text-secondary {
  --page-text-opacity: 1;
  color: rgba(var(--page-secondary-rgb), var(--page-text-opacity));
}

.text-success {
  --page-text-opacity: 1;
  color: rgba(var(--page-success-rgb), var(--page-text-opacity));
}

.text-info {
  --page-text-opacity: 1;
  color: rgba(var(--page-info-rgb), var(--page-text-opacity));
}

.text-warning {
  --page-text-opacity: 1;
  color: rgba(var(--page-warning-rgb), var(--page-text-opacity));
}

.text-danger {
  --page-text-opacity: 1;
  color: rgba(var(--page-danger-rgb), var(--page-text-opacity));
}

.text-light {
  --page-text-opacity: 1;
  color: rgba(var(--page-light-rgb), var(--page-text-opacity));
}

.text-dark {
  --page-text-opacity: 1;
  color: rgba(var(--page-dark-rgb), var(--page-text-opacity));
}

.text-black {
  --page-text-opacity: 1;
  color: rgba(var(--page-black-rgb), var(--page-text-opacity));
}

.text-white {
  --page-text-opacity: 1;
  color: rgba(var(--page-white-rgb), var(--page-text-opacity));
}

.text-gray {
  --page-text-opacity: 1;
  color: rgba(var(--page-gray-rgb), var(--page-text-opacity));
}

.text-silver {
  --page-text-opacity: 1;
  color: rgba(var(--page-silver-rgb), var(--page-text-opacity));
}

.text-smoky {
  --page-text-opacity: 1;
  color: rgba(var(--page-smoky-rgb), var(--page-text-opacity));
}

.text-scarlet {
  --page-text-opacity: 1;
  color: rgba(var(--page-scarlet-rgb), var(--page-text-opacity));
}

.text-body {
  --page-text-opacity: 1;
  color: rgba(var(--page-body-color-rgb), var(--page-text-opacity));
}

.text-muted {
  --page-text-opacity: 1;
  color: var(--page-secondary-color);
}

.text-black-50 {
  --page-text-opacity: 1;
  color: #00000080;
}

.text-white-50 {
  --page-text-opacity: 1;
  color: #ffffff80;
}

.text-body-secondary {
  --page-text-opacity: 1;
  color: var(--page-secondary-color);
}

.text-body-tertiary {
  --page-text-opacity: 1;
  color: var(--page-tertiary-color);
}

.text-body-emphasis {
  --page-text-opacity: 1;
  color: var(--page-emphasis-color);
}

.text-reset {
  --page-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --page-text-opacity: .25;
}

.text-opacity-50 {
  --page-text-opacity: .5;
}

.text-opacity-75 {
  --page-text-opacity: .75;
}

.text-opacity-100 {
  --page-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--page-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--page-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--page-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--page-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--page-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--page-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--page-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--page-dark-text-emphasis);
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --page-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --page-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --page-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --page-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --page-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em;
}

.link-underline-primary {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-primary-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-primary-rgb), var(--page-link-underline-opacity));
}

.link-underline-secondary {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-secondary-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-secondary-rgb), var(--page-link-underline-opacity));
}

.link-underline-success {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-success-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-success-rgb), var(--page-link-underline-opacity));
}

.link-underline-info {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-info-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-info-rgb), var(--page-link-underline-opacity));
}

.link-underline-warning {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-warning-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-warning-rgb), var(--page-link-underline-opacity));
}

.link-underline-danger {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-danger-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-danger-rgb), var(--page-link-underline-opacity));
}

.link-underline-light {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-light-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-light-rgb), var(--page-link-underline-opacity));
}

.link-underline-dark {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-dark-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-dark-rgb), var(--page-link-underline-opacity));
}

.link-underline-black {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-black-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-black-rgb), var(--page-link-underline-opacity));
}

.link-underline-white {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-white-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-white-rgb), var(--page-link-underline-opacity));
}

.link-underline-gray {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-gray-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-gray-rgb), var(--page-link-underline-opacity));
}

.link-underline-silver {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-silver-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-silver-rgb), var(--page-link-underline-opacity));
}

.link-underline-smoky {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-smoky-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-smoky-rgb), var(--page-link-underline-opacity));
}

.link-underline-scarlet {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-scarlet-rgb), var(--page-link-underline-opacity));
  text-decoration-color: rgba(var(--page-scarlet-rgb), var(--page-link-underline-opacity));
}

.link-underline {
  --page-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--page-link-color-rgb), var(--page-link-underline-opacity, 1));
  text-decoration-color: rgba(var(--page-link-color-rgb), var(--page-link-underline-opacity, 1));
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --page-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --page-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --page-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --page-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --page-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --page-link-underline-opacity: 1;
}

.bg-primary {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-primary-rgb), var(--page-bg-opacity));
}

.bg-secondary {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-secondary-rgb), var(--page-bg-opacity));
}

.bg-success {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-success-rgb), var(--page-bg-opacity));
}

.bg-info {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-info-rgb), var(--page-bg-opacity));
}

.bg-warning {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-warning-rgb), var(--page-bg-opacity));
}

.bg-danger {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-danger-rgb), var(--page-bg-opacity));
}

.bg-light {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-light-rgb), var(--page-bg-opacity));
}

.bg-dark {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-dark-rgb), var(--page-bg-opacity));
}

.bg-black {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-black-rgb), var(--page-bg-opacity));
}

.bg-white {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-white-rgb), var(--page-bg-opacity));
}

.bg-gray {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-gray-rgb), var(--page-bg-opacity));
}

.bg-silver {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-silver-rgb), var(--page-bg-opacity));
}

.bg-smoky {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-smoky-rgb), var(--page-bg-opacity));
}

.bg-scarlet {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-scarlet-rgb), var(--page-bg-opacity));
}

.bg-body {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-body-bg-rgb), var(--page-bg-opacity));
}

.bg-transparent {
  --page-bg-opacity: 1;
  background-color: #0000;
}

.bg-body-secondary {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-secondary-bg-rgb), var(--page-bg-opacity));
}

.bg-body-tertiary {
  --page-bg-opacity: 1;
  background-color: rgba(var(--page-tertiary-bg-rgb), var(--page-bg-opacity));
}

.bg-opacity-10 {
  --page-bg-opacity: .1;
}

.bg-opacity-25 {
  --page-bg-opacity: .25;
}

.bg-opacity-50 {
  --page-bg-opacity: .5;
}

.bg-opacity-75 {
  --page-bg-opacity: .75;
}

.bg-opacity-100 {
  --page-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--page-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--page-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--page-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--page-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--page-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--page-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--page-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--page-dark-bg-subtle);
}

.user-select-all {
  -webkit-user-select: all;
  user-select: all;
}

.user-select-auto {
  -webkit-user-select: auto;
  user-select: auto;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--page-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--page-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--page-border-radius);
}

.rounded-3 {
  border-radius: var(--page-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--page-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--page-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--page-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (width >= 375px) {
  .float-m-start {
    float: left;
  }

  .float-m-end {
    float: right;
  }

  .float-m-none {
    float: none;
  }

  .object-fit-m-contain {
    object-fit: contain;
  }

  .object-fit-m-cover {
    object-fit: cover;
  }

  .object-fit-m-fill {
    object-fit: fill;
  }

  .object-fit-m-scale {
    object-fit: scale-down;
  }

  .object-fit-m-none {
    object-fit: none;
  }

  .d-m-inline {
    display: inline;
  }

  .d-m-inline-block {
    display: inline-block;
  }

  .d-m-block {
    display: block;
  }

  .d-m-flex {
    display: flex;
  }

  .d-m-inline-flex {
    display: inline-flex;
  }

  .d-m-none {
    display: none;
  }

  .flex-m-fill {
    flex: auto;
  }

  .flex-m-row {
    flex-direction: row;
  }

  .flex-m-column {
    flex-direction: column;
  }

  .flex-m-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-m-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-m-grow-0 {
    flex-grow: 0;
  }

  .flex-m-grow-1 {
    flex-grow: 1;
  }

  .flex-m-shrink-0 {
    flex-shrink: 0;
  }

  .flex-m-shrink-1 {
    flex-shrink: 1;
  }

  .flex-m-wrap {
    flex-wrap: wrap;
  }

  .flex-m-nowrap {
    flex-wrap: nowrap;
  }

  .flex-m-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-m-start {
    justify-content: flex-start;
  }

  .justify-content-m-end {
    justify-content: flex-end;
  }

  .justify-content-m-center {
    justify-content: center;
  }

  .justify-content-m-between {
    justify-content: space-between;
  }

  .justify-content-m-around {
    justify-content: space-around;
  }

  .justify-content-m-evenly {
    justify-content: space-evenly;
  }

  .align-items-m-start {
    align-items: flex-start;
  }

  .align-items-m-end {
    align-items: flex-end;
  }

  .align-items-m-center {
    align-items: center;
  }

  .align-content-m-start {
    align-content: flex-start;
  }

  .align-content-m-end {
    align-content: flex-end;
  }

  .align-content-m-center {
    align-content: center;
  }

  .align-content-m-between {
    align-content: space-between;
  }

  .align-content-m-around {
    align-content: space-around;
  }

  .align-self-m-start {
    align-self: flex-start;
  }

  .align-self-m-end {
    align-self: flex-end;
  }

  .align-self-m-center {
    align-self: center;
  }

  .order-m-first {
    order: -1;
  }

  .order-m-0 {
    order: 0;
  }

  .order-m-1 {
    order: 1;
  }

  .order-m-2 {
    order: 2;
  }

  .order-m-3 {
    order: 3;
  }

  .order-m-4 {
    order: 4;
  }

  .order-m-5 {
    order: 5;
  }

  .order-m-last {
    order: 6;
  }

  .m-m-0 {
    margin: 0;
  }

  .m-m-1 {
    margin: .1875rem;
  }

  .m-m-2 {
    margin: .375rem;
  }

  .m-m-3 {
    margin: .75rem;
  }

  .m-m-4 {
    margin: 1.25025rem;
  }

  .m-m-5 {
    margin: 2.1rem;
  }

  .m-m-6 {
    margin: 2.625rem;
  }

  .m-m-auto {
    margin: auto;
  }

  .mx-m-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-m-1 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .mx-m-2 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .mx-m-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-m-4 {
    margin-left: 1.25025rem;
    margin-right: 1.25025rem;
  }

  .mx-m-5 {
    margin-left: 2.1rem;
    margin-right: 2.1rem;
  }

  .mx-m-6 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }

  .mx-m-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-m-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-m-1 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .my-m-2 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .my-m-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-m-4 {
    margin-top: 1.25025rem;
    margin-bottom: 1.25025rem;
  }

  .my-m-5 {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .my-m-6 {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
  }

  .my-m-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-m-0 {
    margin-top: 0;
  }

  .mt-m-1 {
    margin-top: .1875rem;
  }

  .mt-m-2 {
    margin-top: .375rem;
  }

  .mt-m-3 {
    margin-top: .75rem;
  }

  .mt-m-4 {
    margin-top: 1.25025rem;
  }

  .mt-m-5 {
    margin-top: 2.1rem;
  }

  .mt-m-6 {
    margin-top: 2.625rem;
  }

  .mt-m-auto {
    margin-top: auto;
  }

  .me-m-0 {
    margin-right: 0;
  }

  .me-m-1 {
    margin-right: .1875rem;
  }

  .me-m-2 {
    margin-right: .375rem;
  }

  .me-m-3 {
    margin-right: .75rem;
  }

  .me-m-4 {
    margin-right: 1.25025rem;
  }

  .me-m-5 {
    margin-right: 2.1rem;
  }

  .me-m-6 {
    margin-right: 2.625rem;
  }

  .me-m-auto {
    margin-right: auto;
  }

  .mb-m-0 {
    margin-bottom: 0;
  }

  .mb-m-1 {
    margin-bottom: .1875rem;
  }

  .mb-m-2 {
    margin-bottom: .375rem;
  }

  .mb-m-3 {
    margin-bottom: .75rem;
  }

  .mb-m-4 {
    margin-bottom: 1.25025rem;
  }

  .mb-m-5 {
    margin-bottom: 2.1rem;
  }

  .mb-m-6 {
    margin-bottom: 2.625rem;
  }

  .mb-m-auto {
    margin-bottom: auto;
  }

  .ms-m-0 {
    margin-left: 0;
  }

  .ms-m-1 {
    margin-left: .1875rem;
  }

  .ms-m-2 {
    margin-left: .375rem;
  }

  .ms-m-3 {
    margin-left: .75rem;
  }

  .ms-m-4 {
    margin-left: 1.25025rem;
  }

  .ms-m-5 {
    margin-left: 2.1rem;
  }

  .ms-m-6 {
    margin-left: 2.625rem;
  }

  .ms-m-auto {
    margin-left: auto;
  }

  .m-m-n1 {
    margin: -.1875rem;
  }

  .m-m-n2 {
    margin: -.375rem;
  }

  .m-m-n3 {
    margin: -.75rem;
  }

  .m-m-n4 {
    margin: -1.25025rem;
  }

  .m-m-n5 {
    margin: -2.1rem;
  }

  .m-m-n6 {
    margin: -2.625rem;
  }

  .mx-m-n1 {
    margin-left: -.1875rem;
    margin-right: -.1875rem;
  }

  .mx-m-n2 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .mx-m-n3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .mx-m-n4 {
    margin-left: -1.25025rem;
    margin-right: -1.25025rem;
  }

  .mx-m-n5 {
    margin-left: -2.1rem;
    margin-right: -2.1rem;
  }

  .mx-m-n6 {
    margin-left: -2.625rem;
    margin-right: -2.625rem;
  }

  .my-m-n1 {
    margin-top: -.1875rem;
    margin-bottom: -.1875rem;
  }

  .my-m-n2 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .my-m-n3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .my-m-n4 {
    margin-top: -1.25025rem;
    margin-bottom: -1.25025rem;
  }

  .my-m-n5 {
    margin-top: -2.1rem;
    margin-bottom: -2.1rem;
  }

  .my-m-n6 {
    margin-top: -2.625rem;
    margin-bottom: -2.625rem;
  }

  .mt-m-n1 {
    margin-top: -.1875rem;
  }

  .mt-m-n2 {
    margin-top: -.375rem;
  }

  .mt-m-n3 {
    margin-top: -.75rem;
  }

  .mt-m-n4 {
    margin-top: -1.25025rem;
  }

  .mt-m-n5 {
    margin-top: -2.1rem;
  }

  .mt-m-n6 {
    margin-top: -2.625rem;
  }

  .me-m-n1 {
    margin-right: -.1875rem;
  }

  .me-m-n2 {
    margin-right: -.375rem;
  }

  .me-m-n3 {
    margin-right: -.75rem;
  }

  .me-m-n4 {
    margin-right: -1.25025rem;
  }

  .me-m-n5 {
    margin-right: -2.1rem;
  }

  .me-m-n6 {
    margin-right: -2.625rem;
  }

  .mb-m-n1 {
    margin-bottom: -.1875rem;
  }

  .mb-m-n2 {
    margin-bottom: -.375rem;
  }

  .mb-m-n3 {
    margin-bottom: -.75rem;
  }

  .mb-m-n4 {
    margin-bottom: -1.25025rem;
  }

  .mb-m-n5 {
    margin-bottom: -2.1rem;
  }

  .mb-m-n6 {
    margin-bottom: -2.625rem;
  }

  .ms-m-n1 {
    margin-left: -.1875rem;
  }

  .ms-m-n2 {
    margin-left: -.375rem;
  }

  .ms-m-n3 {
    margin-left: -.75rem;
  }

  .ms-m-n4 {
    margin-left: -1.25025rem;
  }

  .ms-m-n5 {
    margin-left: -2.1rem;
  }

  .ms-m-n6 {
    margin-left: -2.625rem;
  }

  .p-m-0 {
    padding: 0;
  }

  .p-m-1 {
    padding: .1875rem;
  }

  .p-m-2 {
    padding: .375rem;
  }

  .p-m-3 {
    padding: .75rem;
  }

  .p-m-4 {
    padding: 1.25025rem;
  }

  .p-m-5 {
    padding: 2.1rem;
  }

  .p-m-6 {
    padding: 2.625rem;
  }

  .px-m-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-m-1 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .px-m-2 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .px-m-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-m-4 {
    padding-left: 1.25025rem;
    padding-right: 1.25025rem;
  }

  .px-m-5 {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .px-m-6 {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  }

  .py-m-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-m-1 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .py-m-2 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-m-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-m-4 {
    padding-top: 1.25025rem;
    padding-bottom: 1.25025rem;
  }

  .py-m-5 {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }

  .py-m-6 {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
  }

  .pt-m-0 {
    padding-top: 0;
  }

  .pt-m-1 {
    padding-top: .1875rem;
  }

  .pt-m-2 {
    padding-top: .375rem;
  }

  .pt-m-3 {
    padding-top: .75rem;
  }

  .pt-m-4 {
    padding-top: 1.25025rem;
  }

  .pt-m-5 {
    padding-top: 2.1rem;
  }

  .pt-m-6 {
    padding-top: 2.625rem;
  }

  .pe-m-0 {
    padding-right: 0;
  }

  .pe-m-1 {
    padding-right: .1875rem;
  }

  .pe-m-2 {
    padding-right: .375rem;
  }

  .pe-m-3 {
    padding-right: .75rem;
  }

  .pe-m-4 {
    padding-right: 1.25025rem;
  }

  .pe-m-5 {
    padding-right: 2.1rem;
  }

  .pe-m-6 {
    padding-right: 2.625rem;
  }

  .pb-m-0 {
    padding-bottom: 0;
  }

  .pb-m-1 {
    padding-bottom: .1875rem;
  }

  .pb-m-2 {
    padding-bottom: .375rem;
  }

  .pb-m-3 {
    padding-bottom: .75rem;
  }

  .pb-m-4 {
    padding-bottom: 1.25025rem;
  }

  .pb-m-5 {
    padding-bottom: 2.1rem;
  }

  .pb-m-6 {
    padding-bottom: 2.625rem;
  }

  .ps-m-0 {
    padding-left: 0;
  }

  .ps-m-1 {
    padding-left: .1875rem;
  }

  .ps-m-2 {
    padding-left: .375rem;
  }

  .ps-m-3 {
    padding-left: .75rem;
  }

  .ps-m-4 {
    padding-left: 1.25025rem;
  }

  .ps-m-5 {
    padding-left: 2.1rem;
  }

  .ps-m-6 {
    padding-left: 2.625rem;
  }

  .row-gap-m-0 {
    row-gap: 0;
  }

  .row-gap-m-1 {
    row-gap: .1875rem;
  }

  .row-gap-m-2 {
    row-gap: .375rem;
  }

  .row-gap-m-3 {
    row-gap: .75rem;
  }

  .row-gap-m-4 {
    row-gap: 1.25025rem;
  }

  .row-gap-m-5 {
    row-gap: 2.1rem;
  }

  .row-gap-m-6 {
    row-gap: 2.625rem;
  }

  .column-gap-m-0 {
    column-gap: 0;
  }

  .column-gap-m-1 {
    column-gap: .1875rem;
  }

  .column-gap-m-2 {
    column-gap: .375rem;
  }

  .column-gap-m-3 {
    column-gap: .75rem;
  }

  .column-gap-m-4 {
    column-gap: 1.25025rem;
  }

  .column-gap-m-5 {
    column-gap: 2.1rem;
  }

  .column-gap-m-6 {
    column-gap: 2.625rem;
  }

  .text-m-start {
    text-align: left;
  }

  .text-m-end {
    text-align: right;
  }

  .text-m-center {
    text-align: center;
  }
}

@media (width >= 576px) {
  .float-sm-start {
    float: left;
  }

  .float-sm-end {
    float: right;
  }

  .float-sm-none {
    float: none;
  }

  .object-fit-sm-contain {
    object-fit: contain;
  }

  .object-fit-sm-cover {
    object-fit: cover;
  }

  .object-fit-sm-fill {
    object-fit: fill;
  }

  .object-fit-sm-scale {
    object-fit: scale-down;
  }

  .object-fit-sm-none {
    object-fit: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .flex-sm-fill {
    flex: auto;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-last {
    order: 6;
  }

  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: .1875rem;
  }

  .m-sm-2 {
    margin: .375rem;
  }

  .m-sm-3 {
    margin: .75rem;
  }

  .m-sm-4 {
    margin: 1.25025rem;
  }

  .m-sm-5 {
    margin: 2.1rem;
  }

  .m-sm-6 {
    margin: 2.625rem;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-sm-1 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .mx-sm-2 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .mx-sm-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-sm-4 {
    margin-left: 1.25025rem;
    margin-right: 1.25025rem;
  }

  .mx-sm-5 {
    margin-left: 2.1rem;
    margin-right: 2.1rem;
  }

  .mx-sm-6 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }

  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .my-sm-2 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .my-sm-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-sm-4 {
    margin-top: 1.25025rem;
    margin-bottom: 1.25025rem;
  }

  .my-sm-5 {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .my-sm-6 {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: .1875rem;
  }

  .mt-sm-2 {
    margin-top: .375rem;
  }

  .mt-sm-3 {
    margin-top: .75rem;
  }

  .mt-sm-4 {
    margin-top: 1.25025rem;
  }

  .mt-sm-5 {
    margin-top: 2.1rem;
  }

  .mt-sm-6 {
    margin-top: 2.625rem;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .me-sm-0 {
    margin-right: 0;
  }

  .me-sm-1 {
    margin-right: .1875rem;
  }

  .me-sm-2 {
    margin-right: .375rem;
  }

  .me-sm-3 {
    margin-right: .75rem;
  }

  .me-sm-4 {
    margin-right: 1.25025rem;
  }

  .me-sm-5 {
    margin-right: 2.1rem;
  }

  .me-sm-6 {
    margin-right: 2.625rem;
  }

  .me-sm-auto {
    margin-right: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: .1875rem;
  }

  .mb-sm-2 {
    margin-bottom: .375rem;
  }

  .mb-sm-3 {
    margin-bottom: .75rem;
  }

  .mb-sm-4 {
    margin-bottom: 1.25025rem;
  }

  .mb-sm-5 {
    margin-bottom: 2.1rem;
  }

  .mb-sm-6 {
    margin-bottom: 2.625rem;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .ms-sm-0 {
    margin-left: 0;
  }

  .ms-sm-1 {
    margin-left: .1875rem;
  }

  .ms-sm-2 {
    margin-left: .375rem;
  }

  .ms-sm-3 {
    margin-left: .75rem;
  }

  .ms-sm-4 {
    margin-left: 1.25025rem;
  }

  .ms-sm-5 {
    margin-left: 2.1rem;
  }

  .ms-sm-6 {
    margin-left: 2.625rem;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .m-sm-n1 {
    margin: -.1875rem;
  }

  .m-sm-n2 {
    margin: -.375rem;
  }

  .m-sm-n3 {
    margin: -.75rem;
  }

  .m-sm-n4 {
    margin: -1.25025rem;
  }

  .m-sm-n5 {
    margin: -2.1rem;
  }

  .m-sm-n6 {
    margin: -2.625rem;
  }

  .mx-sm-n1 {
    margin-left: -.1875rem;
    margin-right: -.1875rem;
  }

  .mx-sm-n2 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .mx-sm-n3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .mx-sm-n4 {
    margin-left: -1.25025rem;
    margin-right: -1.25025rem;
  }

  .mx-sm-n5 {
    margin-left: -2.1rem;
    margin-right: -2.1rem;
  }

  .mx-sm-n6 {
    margin-left: -2.625rem;
    margin-right: -2.625rem;
  }

  .my-sm-n1 {
    margin-top: -.1875rem;
    margin-bottom: -.1875rem;
  }

  .my-sm-n2 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .my-sm-n3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .my-sm-n4 {
    margin-top: -1.25025rem;
    margin-bottom: -1.25025rem;
  }

  .my-sm-n5 {
    margin-top: -2.1rem;
    margin-bottom: -2.1rem;
  }

  .my-sm-n6 {
    margin-top: -2.625rem;
    margin-bottom: -2.625rem;
  }

  .mt-sm-n1 {
    margin-top: -.1875rem;
  }

  .mt-sm-n2 {
    margin-top: -.375rem;
  }

  .mt-sm-n3 {
    margin-top: -.75rem;
  }

  .mt-sm-n4 {
    margin-top: -1.25025rem;
  }

  .mt-sm-n5 {
    margin-top: -2.1rem;
  }

  .mt-sm-n6 {
    margin-top: -2.625rem;
  }

  .me-sm-n1 {
    margin-right: -.1875rem;
  }

  .me-sm-n2 {
    margin-right: -.375rem;
  }

  .me-sm-n3 {
    margin-right: -.75rem;
  }

  .me-sm-n4 {
    margin-right: -1.25025rem;
  }

  .me-sm-n5 {
    margin-right: -2.1rem;
  }

  .me-sm-n6 {
    margin-right: -2.625rem;
  }

  .mb-sm-n1 {
    margin-bottom: -.1875rem;
  }

  .mb-sm-n2 {
    margin-bottom: -.375rem;
  }

  .mb-sm-n3 {
    margin-bottom: -.75rem;
  }

  .mb-sm-n4 {
    margin-bottom: -1.25025rem;
  }

  .mb-sm-n5 {
    margin-bottom: -2.1rem;
  }

  .mb-sm-n6 {
    margin-bottom: -2.625rem;
  }

  .ms-sm-n1 {
    margin-left: -.1875rem;
  }

  .ms-sm-n2 {
    margin-left: -.375rem;
  }

  .ms-sm-n3 {
    margin-left: -.75rem;
  }

  .ms-sm-n4 {
    margin-left: -1.25025rem;
  }

  .ms-sm-n5 {
    margin-left: -2.1rem;
  }

  .ms-sm-n6 {
    margin-left: -2.625rem;
  }

  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: .1875rem;
  }

  .p-sm-2 {
    padding: .375rem;
  }

  .p-sm-3 {
    padding: .75rem;
  }

  .p-sm-4 {
    padding: 1.25025rem;
  }

  .p-sm-5 {
    padding: 2.1rem;
  }

  .p-sm-6 {
    padding: 2.625rem;
  }

  .px-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-sm-1 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .px-sm-2 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .px-sm-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-sm-4 {
    padding-left: 1.25025rem;
    padding-right: 1.25025rem;
  }

  .px-sm-5 {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .px-sm-6 {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .py-sm-2 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-sm-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-sm-4 {
    padding-top: 1.25025rem;
    padding-bottom: 1.25025rem;
  }

  .py-sm-5 {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }

  .py-sm-6 {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: .1875rem;
  }

  .pt-sm-2 {
    padding-top: .375rem;
  }

  .pt-sm-3 {
    padding-top: .75rem;
  }

  .pt-sm-4 {
    padding-top: 1.25025rem;
  }

  .pt-sm-5 {
    padding-top: 2.1rem;
  }

  .pt-sm-6 {
    padding-top: 2.625rem;
  }

  .pe-sm-0 {
    padding-right: 0;
  }

  .pe-sm-1 {
    padding-right: .1875rem;
  }

  .pe-sm-2 {
    padding-right: .375rem;
  }

  .pe-sm-3 {
    padding-right: .75rem;
  }

  .pe-sm-4 {
    padding-right: 1.25025rem;
  }

  .pe-sm-5 {
    padding-right: 2.1rem;
  }

  .pe-sm-6 {
    padding-right: 2.625rem;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: .1875rem;
  }

  .pb-sm-2 {
    padding-bottom: .375rem;
  }

  .pb-sm-3 {
    padding-bottom: .75rem;
  }

  .pb-sm-4 {
    padding-bottom: 1.25025rem;
  }

  .pb-sm-5 {
    padding-bottom: 2.1rem;
  }

  .pb-sm-6 {
    padding-bottom: 2.625rem;
  }

  .ps-sm-0 {
    padding-left: 0;
  }

  .ps-sm-1 {
    padding-left: .1875rem;
  }

  .ps-sm-2 {
    padding-left: .375rem;
  }

  .ps-sm-3 {
    padding-left: .75rem;
  }

  .ps-sm-4 {
    padding-left: 1.25025rem;
  }

  .ps-sm-5 {
    padding-left: 2.1rem;
  }

  .ps-sm-6 {
    padding-left: 2.625rem;
  }

  .row-gap-sm-0 {
    row-gap: 0;
  }

  .row-gap-sm-1 {
    row-gap: .1875rem;
  }

  .row-gap-sm-2 {
    row-gap: .375rem;
  }

  .row-gap-sm-3 {
    row-gap: .75rem;
  }

  .row-gap-sm-4 {
    row-gap: 1.25025rem;
  }

  .row-gap-sm-5 {
    row-gap: 2.1rem;
  }

  .row-gap-sm-6 {
    row-gap: 2.625rem;
  }

  .column-gap-sm-0 {
    column-gap: 0;
  }

  .column-gap-sm-1 {
    column-gap: .1875rem;
  }

  .column-gap-sm-2 {
    column-gap: .375rem;
  }

  .column-gap-sm-3 {
    column-gap: .75rem;
  }

  .column-gap-sm-4 {
    column-gap: 1.25025rem;
  }

  .column-gap-sm-5 {
    column-gap: 2.1rem;
  }

  .column-gap-sm-6 {
    column-gap: 2.625rem;
  }

  .text-sm-start {
    text-align: left;
  }

  .text-sm-end {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left;
  }

  .float-md-end {
    float: right;
  }

  .float-md-none {
    float: none;
  }

  .object-fit-md-contain {
    object-fit: contain;
  }

  .object-fit-md-cover {
    object-fit: cover;
  }

  .object-fit-md-fill {
    object-fit: fill;
  }

  .object-fit-md-scale {
    object-fit: scale-down;
  }

  .object-fit-md-none {
    object-fit: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .flex-md-fill {
    flex: auto;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-last {
    order: 6;
  }

  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: .1875rem;
  }

  .m-md-2 {
    margin: .375rem;
  }

  .m-md-3 {
    margin: .75rem;
  }

  .m-md-4 {
    margin: 1.25025rem;
  }

  .m-md-5 {
    margin: 2.1rem;
  }

  .m-md-6 {
    margin: 2.625rem;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-md-1 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .mx-md-2 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .mx-md-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-md-4 {
    margin-left: 1.25025rem;
    margin-right: 1.25025rem;
  }

  .mx-md-5 {
    margin-left: 2.1rem;
    margin-right: 2.1rem;
  }

  .mx-md-6 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }

  .mx-md-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .my-md-2 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .my-md-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-md-4 {
    margin-top: 1.25025rem;
    margin-bottom: 1.25025rem;
  }

  .my-md-5 {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .my-md-6 {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: .1875rem;
  }

  .mt-md-2 {
    margin-top: .375rem;
  }

  .mt-md-3 {
    margin-top: .75rem;
  }

  .mt-md-4 {
    margin-top: 1.25025rem;
  }

  .mt-md-5 {
    margin-top: 2.1rem;
  }

  .mt-md-6 {
    margin-top: 2.625rem;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .me-md-0 {
    margin-right: 0;
  }

  .me-md-1 {
    margin-right: .1875rem;
  }

  .me-md-2 {
    margin-right: .375rem;
  }

  .me-md-3 {
    margin-right: .75rem;
  }

  .me-md-4 {
    margin-right: 1.25025rem;
  }

  .me-md-5 {
    margin-right: 2.1rem;
  }

  .me-md-6 {
    margin-right: 2.625rem;
  }

  .me-md-auto {
    margin-right: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: .1875rem;
  }

  .mb-md-2 {
    margin-bottom: .375rem;
  }

  .mb-md-3 {
    margin-bottom: .75rem;
  }

  .mb-md-4 {
    margin-bottom: 1.25025rem;
  }

  .mb-md-5 {
    margin-bottom: 2.1rem;
  }

  .mb-md-6 {
    margin-bottom: 2.625rem;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .ms-md-0 {
    margin-left: 0;
  }

  .ms-md-1 {
    margin-left: .1875rem;
  }

  .ms-md-2 {
    margin-left: .375rem;
  }

  .ms-md-3 {
    margin-left: .75rem;
  }

  .ms-md-4 {
    margin-left: 1.25025rem;
  }

  .ms-md-5 {
    margin-left: 2.1rem;
  }

  .ms-md-6 {
    margin-left: 2.625rem;
  }

  .ms-md-auto {
    margin-left: auto;
  }

  .m-md-n1 {
    margin: -.1875rem;
  }

  .m-md-n2 {
    margin: -.375rem;
  }

  .m-md-n3 {
    margin: -.75rem;
  }

  .m-md-n4 {
    margin: -1.25025rem;
  }

  .m-md-n5 {
    margin: -2.1rem;
  }

  .m-md-n6 {
    margin: -2.625rem;
  }

  .mx-md-n1 {
    margin-left: -.1875rem;
    margin-right: -.1875rem;
  }

  .mx-md-n2 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .mx-md-n3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .mx-md-n4 {
    margin-left: -1.25025rem;
    margin-right: -1.25025rem;
  }

  .mx-md-n5 {
    margin-left: -2.1rem;
    margin-right: -2.1rem;
  }

  .mx-md-n6 {
    margin-left: -2.625rem;
    margin-right: -2.625rem;
  }

  .my-md-n1 {
    margin-top: -.1875rem;
    margin-bottom: -.1875rem;
  }

  .my-md-n2 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .my-md-n3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .my-md-n4 {
    margin-top: -1.25025rem;
    margin-bottom: -1.25025rem;
  }

  .my-md-n5 {
    margin-top: -2.1rem;
    margin-bottom: -2.1rem;
  }

  .my-md-n6 {
    margin-top: -2.625rem;
    margin-bottom: -2.625rem;
  }

  .mt-md-n1 {
    margin-top: -.1875rem;
  }

  .mt-md-n2 {
    margin-top: -.375rem;
  }

  .mt-md-n3 {
    margin-top: -.75rem;
  }

  .mt-md-n4 {
    margin-top: -1.25025rem;
  }

  .mt-md-n5 {
    margin-top: -2.1rem;
  }

  .mt-md-n6 {
    margin-top: -2.625rem;
  }

  .me-md-n1 {
    margin-right: -.1875rem;
  }

  .me-md-n2 {
    margin-right: -.375rem;
  }

  .me-md-n3 {
    margin-right: -.75rem;
  }

  .me-md-n4 {
    margin-right: -1.25025rem;
  }

  .me-md-n5 {
    margin-right: -2.1rem;
  }

  .me-md-n6 {
    margin-right: -2.625rem;
  }

  .mb-md-n1 {
    margin-bottom: -.1875rem;
  }

  .mb-md-n2 {
    margin-bottom: -.375rem;
  }

  .mb-md-n3 {
    margin-bottom: -.75rem;
  }

  .mb-md-n4 {
    margin-bottom: -1.25025rem;
  }

  .mb-md-n5 {
    margin-bottom: -2.1rem;
  }

  .mb-md-n6 {
    margin-bottom: -2.625rem;
  }

  .ms-md-n1 {
    margin-left: -.1875rem;
  }

  .ms-md-n2 {
    margin-left: -.375rem;
  }

  .ms-md-n3 {
    margin-left: -.75rem;
  }

  .ms-md-n4 {
    margin-left: -1.25025rem;
  }

  .ms-md-n5 {
    margin-left: -2.1rem;
  }

  .ms-md-n6 {
    margin-left: -2.625rem;
  }

  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: .1875rem;
  }

  .p-md-2 {
    padding: .375rem;
  }

  .p-md-3 {
    padding: .75rem;
  }

  .p-md-4 {
    padding: 1.25025rem;
  }

  .p-md-5 {
    padding: 2.1rem;
  }

  .p-md-6 {
    padding: 2.625rem;
  }

  .px-md-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-md-1 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .px-md-2 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .px-md-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-md-4 {
    padding-left: 1.25025rem;
    padding-right: 1.25025rem;
  }

  .px-md-5 {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .px-md-6 {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .py-md-2 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-md-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-md-4 {
    padding-top: 1.25025rem;
    padding-bottom: 1.25025rem;
  }

  .py-md-5 {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }

  .py-md-6 {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: .1875rem;
  }

  .pt-md-2 {
    padding-top: .375rem;
  }

  .pt-md-3 {
    padding-top: .75rem;
  }

  .pt-md-4 {
    padding-top: 1.25025rem;
  }

  .pt-md-5 {
    padding-top: 2.1rem;
  }

  .pt-md-6 {
    padding-top: 2.625rem;
  }

  .pe-md-0 {
    padding-right: 0;
  }

  .pe-md-1 {
    padding-right: .1875rem;
  }

  .pe-md-2 {
    padding-right: .375rem;
  }

  .pe-md-3 {
    padding-right: .75rem;
  }

  .pe-md-4 {
    padding-right: 1.25025rem;
  }

  .pe-md-5 {
    padding-right: 2.1rem;
  }

  .pe-md-6 {
    padding-right: 2.625rem;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: .1875rem;
  }

  .pb-md-2 {
    padding-bottom: .375rem;
  }

  .pb-md-3 {
    padding-bottom: .75rem;
  }

  .pb-md-4 {
    padding-bottom: 1.25025rem;
  }

  .pb-md-5 {
    padding-bottom: 2.1rem;
  }

  .pb-md-6 {
    padding-bottom: 2.625rem;
  }

  .ps-md-0 {
    padding-left: 0;
  }

  .ps-md-1 {
    padding-left: .1875rem;
  }

  .ps-md-2 {
    padding-left: .375rem;
  }

  .ps-md-3 {
    padding-left: .75rem;
  }

  .ps-md-4 {
    padding-left: 1.25025rem;
  }

  .ps-md-5 {
    padding-left: 2.1rem;
  }

  .ps-md-6 {
    padding-left: 2.625rem;
  }

  .row-gap-md-0 {
    row-gap: 0;
  }

  .row-gap-md-1 {
    row-gap: .1875rem;
  }

  .row-gap-md-2 {
    row-gap: .375rem;
  }

  .row-gap-md-3 {
    row-gap: .75rem;
  }

  .row-gap-md-4 {
    row-gap: 1.25025rem;
  }

  .row-gap-md-5 {
    row-gap: 2.1rem;
  }

  .row-gap-md-6 {
    row-gap: 2.625rem;
  }

  .column-gap-md-0 {
    column-gap: 0;
  }

  .column-gap-md-1 {
    column-gap: .1875rem;
  }

  .column-gap-md-2 {
    column-gap: .375rem;
  }

  .column-gap-md-3 {
    column-gap: .75rem;
  }

  .column-gap-md-4 {
    column-gap: 1.25025rem;
  }

  .column-gap-md-5 {
    column-gap: 2.1rem;
  }

  .column-gap-md-6 {
    column-gap: 2.625rem;
  }

  .text-md-start {
    text-align: left;
  }

  .text-md-end {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}

@media (width >= 1024px) {
  .float-lg-start {
    float: left;
  }

  .float-lg-end {
    float: right;
  }

  .float-lg-none {
    float: none;
  }

  .object-fit-lg-contain {
    object-fit: contain;
  }

  .object-fit-lg-cover {
    object-fit: cover;
  }

  .object-fit-lg-fill {
    object-fit: fill;
  }

  .object-fit-lg-scale {
    object-fit: scale-down;
  }

  .object-fit-lg-none {
    object-fit: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-fill {
    flex: auto;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-last {
    order: 6;
  }

  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: .1875rem;
  }

  .m-lg-2 {
    margin: .375rem;
  }

  .m-lg-3 {
    margin: .75rem;
  }

  .m-lg-4 {
    margin: 1.25025rem;
  }

  .m-lg-5 {
    margin: 2.1rem;
  }

  .m-lg-6 {
    margin: 2.625rem;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-lg-1 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .mx-lg-2 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .mx-lg-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-lg-4 {
    margin-left: 1.25025rem;
    margin-right: 1.25025rem;
  }

  .mx-lg-5 {
    margin-left: 2.1rem;
    margin-right: 2.1rem;
  }

  .mx-lg-6 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }

  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .my-lg-2 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .my-lg-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-lg-4 {
    margin-top: 1.25025rem;
    margin-bottom: 1.25025rem;
  }

  .my-lg-5 {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .my-lg-6 {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: .1875rem;
  }

  .mt-lg-2 {
    margin-top: .375rem;
  }

  .mt-lg-3 {
    margin-top: .75rem;
  }

  .mt-lg-4 {
    margin-top: 1.25025rem;
  }

  .mt-lg-5 {
    margin-top: 2.1rem;
  }

  .mt-lg-6 {
    margin-top: 2.625rem;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .me-lg-0 {
    margin-right: 0;
  }

  .me-lg-1 {
    margin-right: .1875rem;
  }

  .me-lg-2 {
    margin-right: .375rem;
  }

  .me-lg-3 {
    margin-right: .75rem;
  }

  .me-lg-4 {
    margin-right: 1.25025rem;
  }

  .me-lg-5 {
    margin-right: 2.1rem;
  }

  .me-lg-6 {
    margin-right: 2.625rem;
  }

  .me-lg-auto {
    margin-right: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: .1875rem;
  }

  .mb-lg-2 {
    margin-bottom: .375rem;
  }

  .mb-lg-3 {
    margin-bottom: .75rem;
  }

  .mb-lg-4 {
    margin-bottom: 1.25025rem;
  }

  .mb-lg-5 {
    margin-bottom: 2.1rem;
  }

  .mb-lg-6 {
    margin-bottom: 2.625rem;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .ms-lg-1 {
    margin-left: .1875rem;
  }

  .ms-lg-2 {
    margin-left: .375rem;
  }

  .ms-lg-3 {
    margin-left: .75rem;
  }

  .ms-lg-4 {
    margin-left: 1.25025rem;
  }

  .ms-lg-5 {
    margin-left: 2.1rem;
  }

  .ms-lg-6 {
    margin-left: 2.625rem;
  }

  .ms-lg-auto {
    margin-left: auto;
  }

  .m-lg-n1 {
    margin: -.1875rem;
  }

  .m-lg-n2 {
    margin: -.375rem;
  }

  .m-lg-n3 {
    margin: -.75rem;
  }

  .m-lg-n4 {
    margin: -1.25025rem;
  }

  .m-lg-n5 {
    margin: -2.1rem;
  }

  .m-lg-n6 {
    margin: -2.625rem;
  }

  .mx-lg-n1 {
    margin-left: -.1875rem;
    margin-right: -.1875rem;
  }

  .mx-lg-n2 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .mx-lg-n3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .mx-lg-n4 {
    margin-left: -1.25025rem;
    margin-right: -1.25025rem;
  }

  .mx-lg-n5 {
    margin-left: -2.1rem;
    margin-right: -2.1rem;
  }

  .mx-lg-n6 {
    margin-left: -2.625rem;
    margin-right: -2.625rem;
  }

  .my-lg-n1 {
    margin-top: -.1875rem;
    margin-bottom: -.1875rem;
  }

  .my-lg-n2 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .my-lg-n3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .my-lg-n4 {
    margin-top: -1.25025rem;
    margin-bottom: -1.25025rem;
  }

  .my-lg-n5 {
    margin-top: -2.1rem;
    margin-bottom: -2.1rem;
  }

  .my-lg-n6 {
    margin-top: -2.625rem;
    margin-bottom: -2.625rem;
  }

  .mt-lg-n1 {
    margin-top: -.1875rem;
  }

  .mt-lg-n2 {
    margin-top: -.375rem;
  }

  .mt-lg-n3 {
    margin-top: -.75rem;
  }

  .mt-lg-n4 {
    margin-top: -1.25025rem;
  }

  .mt-lg-n5 {
    margin-top: -2.1rem;
  }

  .mt-lg-n6 {
    margin-top: -2.625rem;
  }

  .me-lg-n1 {
    margin-right: -.1875rem;
  }

  .me-lg-n2 {
    margin-right: -.375rem;
  }

  .me-lg-n3 {
    margin-right: -.75rem;
  }

  .me-lg-n4 {
    margin-right: -1.25025rem;
  }

  .me-lg-n5 {
    margin-right: -2.1rem;
  }

  .me-lg-n6 {
    margin-right: -2.625rem;
  }

  .mb-lg-n1 {
    margin-bottom: -.1875rem;
  }

  .mb-lg-n2 {
    margin-bottom: -.375rem;
  }

  .mb-lg-n3 {
    margin-bottom: -.75rem;
  }

  .mb-lg-n4 {
    margin-bottom: -1.25025rem;
  }

  .mb-lg-n5 {
    margin-bottom: -2.1rem;
  }

  .mb-lg-n6 {
    margin-bottom: -2.625rem;
  }

  .ms-lg-n1 {
    margin-left: -.1875rem;
  }

  .ms-lg-n2 {
    margin-left: -.375rem;
  }

  .ms-lg-n3 {
    margin-left: -.75rem;
  }

  .ms-lg-n4 {
    margin-left: -1.25025rem;
  }

  .ms-lg-n5 {
    margin-left: -2.1rem;
  }

  .ms-lg-n6 {
    margin-left: -2.625rem;
  }

  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: .1875rem;
  }

  .p-lg-2 {
    padding: .375rem;
  }

  .p-lg-3 {
    padding: .75rem;
  }

  .p-lg-4 {
    padding: 1.25025rem;
  }

  .p-lg-5 {
    padding: 2.1rem;
  }

  .p-lg-6 {
    padding: 2.625rem;
  }

  .px-lg-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-lg-1 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .px-lg-2 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .px-lg-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-lg-4 {
    padding-left: 1.25025rem;
    padding-right: 1.25025rem;
  }

  .px-lg-5 {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .px-lg-6 {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .py-lg-2 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-lg-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-lg-4 {
    padding-top: 1.25025rem;
    padding-bottom: 1.25025rem;
  }

  .py-lg-5 {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }

  .py-lg-6 {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: .1875rem;
  }

  .pt-lg-2 {
    padding-top: .375rem;
  }

  .pt-lg-3 {
    padding-top: .75rem;
  }

  .pt-lg-4 {
    padding-top: 1.25025rem;
  }

  .pt-lg-5 {
    padding-top: 2.1rem;
  }

  .pt-lg-6 {
    padding-top: 2.625rem;
  }

  .pe-lg-0 {
    padding-right: 0;
  }

  .pe-lg-1 {
    padding-right: .1875rem;
  }

  .pe-lg-2 {
    padding-right: .375rem;
  }

  .pe-lg-3 {
    padding-right: .75rem;
  }

  .pe-lg-4 {
    padding-right: 1.25025rem;
  }

  .pe-lg-5 {
    padding-right: 2.1rem;
  }

  .pe-lg-6 {
    padding-right: 2.625rem;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: .1875rem;
  }

  .pb-lg-2 {
    padding-bottom: .375rem;
  }

  .pb-lg-3 {
    padding-bottom: .75rem;
  }

  .pb-lg-4 {
    padding-bottom: 1.25025rem;
  }

  .pb-lg-5 {
    padding-bottom: 2.1rem;
  }

  .pb-lg-6 {
    padding-bottom: 2.625rem;
  }

  .ps-lg-0 {
    padding-left: 0;
  }

  .ps-lg-1 {
    padding-left: .1875rem;
  }

  .ps-lg-2 {
    padding-left: .375rem;
  }

  .ps-lg-3 {
    padding-left: .75rem;
  }

  .ps-lg-4 {
    padding-left: 1.25025rem;
  }

  .ps-lg-5 {
    padding-left: 2.1rem;
  }

  .ps-lg-6 {
    padding-left: 2.625rem;
  }

  .row-gap-lg-0 {
    row-gap: 0;
  }

  .row-gap-lg-1 {
    row-gap: .1875rem;
  }

  .row-gap-lg-2 {
    row-gap: .375rem;
  }

  .row-gap-lg-3 {
    row-gap: .75rem;
  }

  .row-gap-lg-4 {
    row-gap: 1.25025rem;
  }

  .row-gap-lg-5 {
    row-gap: 2.1rem;
  }

  .row-gap-lg-6 {
    row-gap: 2.625rem;
  }

  .column-gap-lg-0 {
    column-gap: 0;
  }

  .column-gap-lg-1 {
    column-gap: .1875rem;
  }

  .column-gap-lg-2 {
    column-gap: .375rem;
  }

  .column-gap-lg-3 {
    column-gap: .75rem;
  }

  .column-gap-lg-4 {
    column-gap: 1.25025rem;
  }

  .column-gap-lg-5 {
    column-gap: 2.1rem;
  }

  .column-gap-lg-6 {
    column-gap: 2.625rem;
  }

  .text-lg-start {
    text-align: left;
  }

  .text-lg-end {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}

@media (width >= 1580px) {
  .float-xl-start {
    float: left;
  }

  .float-xl-end {
    float: right;
  }

  .float-xl-none {
    float: none;
  }

  .object-fit-xl-contain {
    object-fit: contain;
  }

  .object-fit-xl-cover {
    object-fit: cover;
  }

  .object-fit-xl-fill {
    object-fit: fill;
  }

  .object-fit-xl-scale {
    object-fit: scale-down;
  }

  .object-fit-xl-none {
    object-fit: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-fill {
    flex: auto;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-last {
    order: 6;
  }

  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: .1875rem;
  }

  .m-xl-2 {
    margin: .375rem;
  }

  .m-xl-3 {
    margin: .75rem;
  }

  .m-xl-4 {
    margin: 1.25025rem;
  }

  .m-xl-5 {
    margin: 2.1rem;
  }

  .m-xl-6 {
    margin: 2.625rem;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-xl-1 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .mx-xl-2 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .mx-xl-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-xl-4 {
    margin-left: 1.25025rem;
    margin-right: 1.25025rem;
  }

  .mx-xl-5 {
    margin-left: 2.1rem;
    margin-right: 2.1rem;
  }

  .mx-xl-6 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }

  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .my-xl-2 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .my-xl-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-xl-4 {
    margin-top: 1.25025rem;
    margin-bottom: 1.25025rem;
  }

  .my-xl-5 {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .my-xl-6 {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: .1875rem;
  }

  .mt-xl-2 {
    margin-top: .375rem;
  }

  .mt-xl-3 {
    margin-top: .75rem;
  }

  .mt-xl-4 {
    margin-top: 1.25025rem;
  }

  .mt-xl-5 {
    margin-top: 2.1rem;
  }

  .mt-xl-6 {
    margin-top: 2.625rem;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .me-xl-0 {
    margin-right: 0;
  }

  .me-xl-1 {
    margin-right: .1875rem;
  }

  .me-xl-2 {
    margin-right: .375rem;
  }

  .me-xl-3 {
    margin-right: .75rem;
  }

  .me-xl-4 {
    margin-right: 1.25025rem;
  }

  .me-xl-5 {
    margin-right: 2.1rem;
  }

  .me-xl-6 {
    margin-right: 2.625rem;
  }

  .me-xl-auto {
    margin-right: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: .1875rem;
  }

  .mb-xl-2 {
    margin-bottom: .375rem;
  }

  .mb-xl-3 {
    margin-bottom: .75rem;
  }

  .mb-xl-4 {
    margin-bottom: 1.25025rem;
  }

  .mb-xl-5 {
    margin-bottom: 2.1rem;
  }

  .mb-xl-6 {
    margin-bottom: 2.625rem;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .ms-xl-1 {
    margin-left: .1875rem;
  }

  .ms-xl-2 {
    margin-left: .375rem;
  }

  .ms-xl-3 {
    margin-left: .75rem;
  }

  .ms-xl-4 {
    margin-left: 1.25025rem;
  }

  .ms-xl-5 {
    margin-left: 2.1rem;
  }

  .ms-xl-6 {
    margin-left: 2.625rem;
  }

  .ms-xl-auto {
    margin-left: auto;
  }

  .m-xl-n1 {
    margin: -.1875rem;
  }

  .m-xl-n2 {
    margin: -.375rem;
  }

  .m-xl-n3 {
    margin: -.75rem;
  }

  .m-xl-n4 {
    margin: -1.25025rem;
  }

  .m-xl-n5 {
    margin: -2.1rem;
  }

  .m-xl-n6 {
    margin: -2.625rem;
  }

  .mx-xl-n1 {
    margin-left: -.1875rem;
    margin-right: -.1875rem;
  }

  .mx-xl-n2 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .mx-xl-n3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .mx-xl-n4 {
    margin-left: -1.25025rem;
    margin-right: -1.25025rem;
  }

  .mx-xl-n5 {
    margin-left: -2.1rem;
    margin-right: -2.1rem;
  }

  .mx-xl-n6 {
    margin-left: -2.625rem;
    margin-right: -2.625rem;
  }

  .my-xl-n1 {
    margin-top: -.1875rem;
    margin-bottom: -.1875rem;
  }

  .my-xl-n2 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .my-xl-n3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .my-xl-n4 {
    margin-top: -1.25025rem;
    margin-bottom: -1.25025rem;
  }

  .my-xl-n5 {
    margin-top: -2.1rem;
    margin-bottom: -2.1rem;
  }

  .my-xl-n6 {
    margin-top: -2.625rem;
    margin-bottom: -2.625rem;
  }

  .mt-xl-n1 {
    margin-top: -.1875rem;
  }

  .mt-xl-n2 {
    margin-top: -.375rem;
  }

  .mt-xl-n3 {
    margin-top: -.75rem;
  }

  .mt-xl-n4 {
    margin-top: -1.25025rem;
  }

  .mt-xl-n5 {
    margin-top: -2.1rem;
  }

  .mt-xl-n6 {
    margin-top: -2.625rem;
  }

  .me-xl-n1 {
    margin-right: -.1875rem;
  }

  .me-xl-n2 {
    margin-right: -.375rem;
  }

  .me-xl-n3 {
    margin-right: -.75rem;
  }

  .me-xl-n4 {
    margin-right: -1.25025rem;
  }

  .me-xl-n5 {
    margin-right: -2.1rem;
  }

  .me-xl-n6 {
    margin-right: -2.625rem;
  }

  .mb-xl-n1 {
    margin-bottom: -.1875rem;
  }

  .mb-xl-n2 {
    margin-bottom: -.375rem;
  }

  .mb-xl-n3 {
    margin-bottom: -.75rem;
  }

  .mb-xl-n4 {
    margin-bottom: -1.25025rem;
  }

  .mb-xl-n5 {
    margin-bottom: -2.1rem;
  }

  .mb-xl-n6 {
    margin-bottom: -2.625rem;
  }

  .ms-xl-n1 {
    margin-left: -.1875rem;
  }

  .ms-xl-n2 {
    margin-left: -.375rem;
  }

  .ms-xl-n3 {
    margin-left: -.75rem;
  }

  .ms-xl-n4 {
    margin-left: -1.25025rem;
  }

  .ms-xl-n5 {
    margin-left: -2.1rem;
  }

  .ms-xl-n6 {
    margin-left: -2.625rem;
  }

  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: .1875rem;
  }

  .p-xl-2 {
    padding: .375rem;
  }

  .p-xl-3 {
    padding: .75rem;
  }

  .p-xl-4 {
    padding: 1.25025rem;
  }

  .p-xl-5 {
    padding: 2.1rem;
  }

  .p-xl-6 {
    padding: 2.625rem;
  }

  .px-xl-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-xl-1 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .px-xl-2 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .px-xl-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-xl-4 {
    padding-left: 1.25025rem;
    padding-right: 1.25025rem;
  }

  .px-xl-5 {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .px-xl-6 {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .py-xl-2 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-xl-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-xl-4 {
    padding-top: 1.25025rem;
    padding-bottom: 1.25025rem;
  }

  .py-xl-5 {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }

  .py-xl-6 {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: .1875rem;
  }

  .pt-xl-2 {
    padding-top: .375rem;
  }

  .pt-xl-3 {
    padding-top: .75rem;
  }

  .pt-xl-4 {
    padding-top: 1.25025rem;
  }

  .pt-xl-5 {
    padding-top: 2.1rem;
  }

  .pt-xl-6 {
    padding-top: 2.625rem;
  }

  .pe-xl-0 {
    padding-right: 0;
  }

  .pe-xl-1 {
    padding-right: .1875rem;
  }

  .pe-xl-2 {
    padding-right: .375rem;
  }

  .pe-xl-3 {
    padding-right: .75rem;
  }

  .pe-xl-4 {
    padding-right: 1.25025rem;
  }

  .pe-xl-5 {
    padding-right: 2.1rem;
  }

  .pe-xl-6 {
    padding-right: 2.625rem;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: .1875rem;
  }

  .pb-xl-2 {
    padding-bottom: .375rem;
  }

  .pb-xl-3 {
    padding-bottom: .75rem;
  }

  .pb-xl-4 {
    padding-bottom: 1.25025rem;
  }

  .pb-xl-5 {
    padding-bottom: 2.1rem;
  }

  .pb-xl-6 {
    padding-bottom: 2.625rem;
  }

  .ps-xl-0 {
    padding-left: 0;
  }

  .ps-xl-1 {
    padding-left: .1875rem;
  }

  .ps-xl-2 {
    padding-left: .375rem;
  }

  .ps-xl-3 {
    padding-left: .75rem;
  }

  .ps-xl-4 {
    padding-left: 1.25025rem;
  }

  .ps-xl-5 {
    padding-left: 2.1rem;
  }

  .ps-xl-6 {
    padding-left: 2.625rem;
  }

  .row-gap-xl-0 {
    row-gap: 0;
  }

  .row-gap-xl-1 {
    row-gap: .1875rem;
  }

  .row-gap-xl-2 {
    row-gap: .375rem;
  }

  .row-gap-xl-3 {
    row-gap: .75rem;
  }

  .row-gap-xl-4 {
    row-gap: 1.25025rem;
  }

  .row-gap-xl-5 {
    row-gap: 2.1rem;
  }

  .row-gap-xl-6 {
    row-gap: 2.625rem;
  }

  .column-gap-xl-0 {
    column-gap: 0;
  }

  .column-gap-xl-1 {
    column-gap: .1875rem;
  }

  .column-gap-xl-2 {
    column-gap: .375rem;
  }

  .column-gap-xl-3 {
    column-gap: .75rem;
  }

  .column-gap-xl-4 {
    column-gap: 1.25025rem;
  }

  .column-gap-xl-5 {
    column-gap: 2.1rem;
  }

  .column-gap-xl-6 {
    column-gap: 2.625rem;
  }

  .text-xl-start {
    text-align: left;
  }

  .text-xl-end {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --page-dropdown-zindex: 1000;
  --page-dropdown-min-width: 10rem;
  --page-dropdown-padding-x: 0;
  --page-dropdown-padding-y: .5rem;
  --page-dropdown-spacer: .125rem;
  --page-dropdown-font-size: 1.125rem;
  --page-dropdown-color: var(--page-body-color);
  --page-dropdown-bg: var(--page-body-bg);
  --page-dropdown-border-color: var(--page-border-color-translucent);
  --page-dropdown-border-radius: var(--page-border-radius);
  --page-dropdown-border-width: var(--page-border-width);
  --page-dropdown-inner-border-radius: calc(var(--page-border-radius)  - var(--page-border-width));
  --page-dropdown-divider-bg: var(--page-border-color-translucent);
  --page-dropdown-divider-margin-y: .375rem;
  --page-dropdown-box-shadow: var(--page-box-shadow);
  --page-dropdown-link-color: var(--page-body-color);
  --page-dropdown-link-hover-color: var(--page-body-color);
  --page-dropdown-link-hover-bg: var(--page-tertiary-bg);
  --page-dropdown-link-active-color: #fff;
  --page-dropdown-link-active-bg: #0d6efd;
  --page-dropdown-link-disabled-color: var(--page-tertiary-color);
  --page-dropdown-item-padding-x: .75rem;
  --page-dropdown-item-padding-y: .1875rem;
  --page-dropdown-header-color: #6c757d;
  --page-dropdown-header-padding-x: .75rem;
  --page-dropdown-header-padding-y: .5rem;
  z-index: var(--page-dropdown-zindex);
  min-width: var(--page-dropdown-min-width);
  padding: var(--page-dropdown-padding-y) var(--page-dropdown-padding-x);
  font-size: var(--page-dropdown-font-size);
  color: var(--page-dropdown-color);
  text-align: left;
  background-color: var(--page-dropdown-bg);
  border: var(--page-dropdown-border-width) solid var(--page-dropdown-border-color);
  border-radius: var(--page-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--page-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 375px) {
  .dropdown-menu-m-start {
    --bs-position: start;
  }

  .dropdown-menu-m-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-m-end {
    --bs-position: end;
  }

  .dropdown-menu-m-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1580px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--page-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--page-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--page-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--page-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--page-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--page-dropdown-item-padding-y) var(--page-dropdown-item-padding-x);
  clear: both;
  color: var(--page-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--page-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--page-dropdown-link-hover-color);
  background-color: var(--page-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--page-dropdown-link-active-color);
  background-color: var(--page-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--page-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--page-dropdown-header-padding-y) var(--page-dropdown-header-padding-x);
  color: var(--page-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .984375rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--page-dropdown-item-padding-y) var(--page-dropdown-item-padding-x);
  color: var(--page-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --page-dropdown-color: #dee2e6;
  --page-dropdown-bg: #343a40;
  --page-dropdown-border-color: var(--page-border-color-translucent);
  --page-dropdown-box-shadow: ;
  --page-dropdown-link-color: #dee2e6;
  --page-dropdown-link-hover-color: #fff;
  --page-dropdown-divider-bg: var(--page-border-color-translucent);
  --page-dropdown-link-hover-bg: #ffffff26;
  --page-dropdown-link-active-color: #fff;
  --page-dropdown-link-active-bg: #0d6efd;
  --page-dropdown-link-disabled-color: #adb5bd;
  --page-dropdown-header-color: #adb5bd;
}

:root {
  --page-primary: #dc0000;
  --page-secondary: #d3d3d3;
  --page-success: #4db762;
  --page-info: #0dcaf0;
  --page-warning: #ffc107;
  --page-danger: #dc3545;
  --page-light: #f8f9fa;
  --page-dark: #212121;
  --page-black: #000;
  --page-white: #fff;
  --page-gray: #999;
  --page-silver: #bfbfbf;
  --page-smoky: #f5f5f5;
  --page-gainsborough: #dcdcdc;
  --page-gainsborough-light: #e5e5e5;
  --page-scarlet: #c0392b;
  --page-primary-rgb: 220, 0, 0;
  --page-secondary-rgb: 211, 211, 211;
  --page-success-rgb: 77, 183, 98;
  --page-info-rgb: 13, 202, 240;
  --page-warning-rgb: 255, 193, 7;
  --page-danger-rgb: 220, 53, 69;
  --page-light-rgb: 248, 249, 250;
  --page-dark-rgb: 33, 33, 33;
  --page-black-rgb: 0, 0, 0;
  --page-white-rgb: 255, 255, 255;
  --page-gray-rgb: 153, 153, 153;
  --page-silver-rgb: 191, 191, 191;
  --page-smoky-rgb: 245, 245, 245;
  --page-scarlet-rgb: 192, 57, 43;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

@media (width >= 1024px) {
  .page {
    height: 100%;
  }

  .page__main {
    padding-top: 3.125rem;
    padding-bottom: 10.625rem;
  }
}

@media (width <= 1023.98px) {
  .page__main {
    padding-top: 1.875rem;
    padding-bottom: 5rem;
  }
}

.page__wrapper {
  flex-direction: column;
  min-height: 100%;
  display: flex;
  position: relative;
}

.page__wrapper .footer {
  flex: none;
}

.page__content {
  flex: 1 0 auto;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

body {
  color: #000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  background-color: #fff;
  margin: 0;
  font-family: Gilroy, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.35;
}

@media (width <= 767.98px) {
  body {
    font-size: 1rem;
  }
}

body::-webkit-scrollbar {
  width: .75rem;
}

body::-webkit-scrollbar-track {
  background: #d3d3d3;
}

body::-webkit-scrollbar-thumb {
  background: #bfbfbf;
}

body::-webkit-scrollbar-thumb:hover {
  background: #999;
}

hr {
  color: #212121;
  background-color: currentColor;
  border: 0;
  margin: 0;
}

hr:not([size]) {
  height: 1px;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-top: 0;
  margin-bottom: .5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.15;
}

h1 {
  font-size: calc(1.33875rem + .808861vw);
}

@media (width >= 1580px) {
  h1 {
    font-size: 2.1375rem;
  }
}

h2 {
  font-size: calc(1.305rem + .501266vw);
}

@media (width >= 1580px) {
  h2 {
    font-size: 1.8rem;
  }
}

h3 {
  font-size: calc(1.29375rem + .398734vw);
}

@media (width >= 1580px) {
  h3 {
    font-size: 1.6875rem;
  }
}

h4 {
  font-size: calc(1.2825rem + .296203vw);
}

@media (width >= 1580px) {
  h4 {
    font-size: 1.575rem;
  }
}

h5 {
  font-size: calc(1.26281rem + .116772vw);
}

@media (width >= 1580px) {
  h5 {
    font-size: 1.37813rem;
  }
}

h6 {
  font-size: calc(1.25044rem + .00398734vw);
}

@media (width >= 1580px) {
  h6 {
    font-size: 1.25438rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: .375rem;
}

abbr[title], abbr[data-bs-original-title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: .375rem;
}

ul, ol, li {
  padding: 0;
  list-style: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1.125rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .375rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1.125rem;
}

b, strong {
  font-weight: 700;
}

small {
  font-size: .78em;
}

mark {
  background-color: #fcf8e3;
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #000;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  direction: ltr;
  unicode-bidi: bidi-override;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1.125rem;
  font-size: .78em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: #d63384;
  word-wrap: break-word;
  font-size: .78em;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #e6e6e6;
  border-radius: 8px;
  padding: .2rem .4rem;
  font-size: .78em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1.125rem;
}

img {
  max-width: 100%;
}

svg {
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
}

svg.svg-icon {
  stroke-width: 0;
  fill: currentColor;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--page-secondary-color);
  text-align: left;
  padding-top: .5625rem;
  padding-bottom: .5625rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  color: #000;
  background-color: #0000;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
}

button:focus, a:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[readonly]:focus-within, input[readonly]:focus-visible, input[readonly]:focus {
  outline: none;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: none;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.26563rem + .142405vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .375rem;
  padding: 0;
}

@media (width >= 1580px) {
  legend {
    font-size: 1.40625rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.header {
  z-index: 5;
  transition-property: transform, background-color, padding-top, padding-bottom;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__logotype {
  width: 13.25rem;
}

.header.header-pinned {
  transform: translateY(0%);
}

.header.header-not-top {
  background-color: #00000080;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.header.header-unpinned {
  transform: translateY(-100%);
}

.header__menu .dropdown-menu {
  --page-dropdown-font-size: 1.125rem;
}

@media (width >= 1580px) {
  .header__menu .dropdown-menu {
    position: relative;
    display: block !important;
  }
}

@media (width <= 1579.98px) {
  .header__menu .dropdown-menu {
    text-align: right;
    min-width: initial;
    white-space: nowrap;
  }

  .header__menu .dropdown-menu:before {
    content: "";
    border-bottom: .46875rem solid #fff;
    border-left: .46875rem solid #0000;
    border-right: .46875rem solid #0000;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: calc(99% - .75rem);
    right: .375rem;
  }
}

.header__nav {
  -webkit-user-select: none;
  user-select: none;
}

@media (width <= 1579.98px) {
  .header__nav {
    color: #000;
    background-color: #fff;
    margin-top: .75rem;
    padding: 1rem 1.5rem;
    font-weight: 700;
    box-shadow: 0 0 .75rem #00000026;
  }
}

.header__nav-link {
  transition: color;
  display: inline-block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .header__nav-link {
    transition: none;
  }
}

@media (width >= 1024px) {
  .header__nav-link {
    color: #fff;
    padding: .75rem 0;
  }

  .header__nav-link + .header__nav-link {
    margin-left: 2.6vw;
  }
}

@media (width <= 1023.98px) {
  .header__nav-link {
    padding: .375rem 0;
  }
}

.header__nav-link:before {
  content: "";
  background-color: var(--landing-accent-color);
  width: 0;
  height: 4px;
  transition-property: left, width;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: 50%;
}

@media (width >= 1024px) {
  .header__nav-link:before {
    bottom: .9975rem;
  }
}

@media (width <= 1023.98px) {
  .header__nav-link:before {
    bottom: .6375rem;
  }
}

@media (width >= 1024px) {
  .header__nav-link:hover, .header__nav-link.is-highlighted {
    color: #fff;
  }
}

.header__nav-link:hover:before, .header__nav-link.is-highlighted:before {
  width: 100%;
  left: 0;
}

.footer {
  overflow: hidden;
}

@media (width >= 1024px) {
  .footer {
    padding-top: 4.6875rem;
    padding-bottom: 3.75rem;
  }
}

@media (width <= 1023.98px) {
  .footer {
    padding-top: 4.375rem;
    padding-bottom: 1.5625rem;
  }
}

.footer__copyright {
  padding-top: 3.75rem;
}

@media (width >= 576px) {
  .footer__copyright-text {
    padding-left: calc(2.875rem + 2px);
    font-size: 1.0625rem;
    position: relative;
  }

  .footer__copyright-text:before {
    content: "";
    background-color: #3f3f3f;
    width: 2px;
    height: 2.125rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 1.4375rem;
    transform: translateY(-50%);
  }

  .footer__copyright .svg-icon {
    width: 3.625rem;
    height: 3.625rem;
  }
}

@media (width <= 575.98px) {
  .footer__copyright .svg-icon {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}

.footer__logotype {
  max-width: 16.25rem;
}

.button {
  text-transform: uppercase;
  background-color: var(--landing-accent-color);
  color: #fff;
  font-weight: 600;
  transition-property: background-color, color;
  transition-duration: .2s;
  display: inline-block;
  position: relative;
}

.button.is-loading {
  pointer-events: none;
}

.button.is-loading .landing__button-content {
  opacity: 0;
  visibility: hidden;
}

.button.is-loading .landing__button-loader {
  opacity: 1;
  visibility: visible;
}

@media (width >= 1024px) {
  .button {
    padding: .9375rem 1.9375rem .8125rem;
    font-size: calc(1.275rem + .227848vw);
  }
}

@media (width >= 1024px) and (width >= 1580px) {
  .button {
    font-size: 1.5rem;
  }
}

@media (width <= 1023.98px) {
  .button {
    padding: .75rem 1.75rem .625rem;
    font-size: 1.125rem;
  }
}

.button:hover {
  color: #fff;
  background-color: var(--landing-accent-color-darken);
}

.button-loader {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-large {
  font-size: calc(1.33875rem + .808861vw);
}

@media (width >= 1580px) {
  .title-large {
    font-size: 2.1375rem;
  }
}

.title-extra-large {
  font-size: calc(1.35rem + .911392vw);
}

@media (width >= 1580px) {
  .title-extra-large {
    font-size: 2.25rem;
  }
}

.title-default {
  font-weight: 500;
}

.title-accent {
  font-family: Gilroy, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, sans-serif;
}

.title-accordion {
  padding-right: 1.875rem;
  position: relative;
}

.title-accordion:after {
  content: "";
  border-top: .34375rem solid #000;
  border-left: .34375rem solid #0000;
  border-right: .34375rem solid #0000;
  width: 0;
  height: 0;
  transition: transform;
  display: block;
  position: absolute;
  top: 50%;
  right: .625rem;
  transform: translateY(-50%)rotateX(0);
}

@media (prefers-reduced-motion: reduce) {
  .title-accordion:after {
    transition: none;
  }
}

[aria-expanded="false"] .title-accordion:after {
  transform: translateY(-50%)rotateX(-180deg);
}

.text-small {
  font-size: .9375rem;
}

.text-regular {
  font-size: 1rem;
}

.text-sub-middle {
  font-size: 1.125rem;
}

.text-middle {
  font-size: 1.25rem;
}

.text-large {
  font-size: calc(1.25625rem + .056962vw);
}

@media (width >= 1580px) {
  .text-large {
    font-size: 1.3125rem;
  }
}

@media (width >= 576px) {
  .text-extra-large {
    font-size: calc(1.275rem + .227848vw);
  }
}

@media (width >= 576px) and (width >= 1580px) {
  .text-extra-large {
    font-size: 1.5rem;
  }
}

@media (width <= 575.98px) {
  .text-extra-large {
    font-size: 1.25rem;
  }
}

.text-gilroy {
  font-family: Gilroy, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, sans-serif;
}

.text-italic {
  font-style: italic;
}

.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-clamp {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-clamp-two {
  -webkit-line-clamp: 2;
}

.text-clamp-three {
  -webkit-line-clamp: 3;
}

.text-clamp-four {
  -webkit-line-clamp: 4;
}

.text-clamp-five {
  -webkit-line-clamp: 5;
}

.text-indent {
  text-indent: .75rem;
}

.text-underlined {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, var(--landing-accent-color), var(--landing-accent-color));
  background-position-x: 0;
  background-position-y: calc(100% - var(--landing-underline-offset-y) * -1);
  background-size: 100% calc(var(--landing-underline-height) * 1px), auto calc(var(--landing-underline-height) * 1px), auto calc(var(--landing-underline-height) * 1px);
  display: inline;
}

ul.list-default {
  padding-left: 1.375rem;
}

ul.list-default li {
  list-style: disc;
}

ol {
  counter-reset: item;
}

ol > li {
  display: block;
}

ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

ol > li + li {
  margin-top: .5rem;
}

.intro {
  background-color: var(--landing-bg-dark);
  position: relative;
  overflow: hidden;
}

@media (width >= 1024px) {
  .intro {
    min-height: 47.6875rem;
  }
}

.intro:after {
  content: "";
  background: radial-gradient(closest-side, #af1917a8, #0000);
  width: 150%;
  height: 200%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(20%, -25%);
}

.intro__title {
  letter-spacing: 1px;
  font-size: calc(1.45rem + 1.82278vw);
  font-weight: 800;
  line-height: 1.4;
}

@media (width >= 1580px) {
  .intro__title {
    font-size: 3.25rem;
  }
}

.intro__content {
  z-index: 1;
  position: relative;
}

@media (width >= 768px) {
  .intro__content {
    padding-top: 15.3125rem;
    padding-bottom: 9.0625rem;
  }
}

@media (width <= 767.98px) {
  .intro__content {
    padding-top: 8.125rem;
    padding-bottom: 8.75rem;
  }
}

.intro__content .button {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border-radius: .625rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  font-size: 1.1875rem;
  font-weight: 700;
}

@media (width >= 768px) {
  .intro__content .button {
    margin-top: 5.3125rem;
  }
}

@media (width <= 767.98px) {
  .intro__content .button {
    margin-top: 2.8125rem;
  }
}

@media (width >= 768px) {
  .description {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

@media (width <= 767.98px) {
  .description {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
}

.clubs {
  background-color: var(--landing-bg-dark);
}

@media (width >= 1024px) {
  .clubs {
    padding-top: 5.625rem;
    padding-bottom: 14.8125rem;
  }
}

@media (width <= 1023.98px) {
  .clubs {
    padding-top: 3.125rem;
    padding-bottom: 5rem;
  }
}

.clubs__bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1024px) {
  .clubs__effect {
    padding-top: 11.875rem;
  }
}

@media (width <= 1023.98px) {
  .clubs__effect {
    padding-top: 3.125rem;
  }
}

.clubs__effect .svg-icon {
  width: .63em;
  height: .69em;
}

.clubs__effect-graph {
  font-size: calc(1.975rem + 6.6076vw);
}

@media (width >= 1580px) {
  .clubs__effect-graph {
    font-size: 8.5rem;
  }
}

.clubs__effect-percent {
  line-height: 1;
}

.clubs__effect-percent-symbol {
  font-weight: 500;
}

.clubs__effect-percent-value {
  font-weight: 800;
}

.clubs__effect-description {
  line-height: 1.25;
}

.clubs__effect-description-accent {
  font-size: calc(1.38125rem + 1.1962vw);
}

@media (width >= 1580px) {
  .clubs__effect-description-accent {
    font-size: 2.5625rem;
  }
}

@media (width >= 1024px) {
  .software {
    padding-top: 6.25rem;
  }
}

@media (width <= 1023.98px) {
  .software {
    padding-top: 3.75rem;
  }
}

@media (width >= 1580px) {
  .software__statistics {
    height: 64.375rem;
  }
}

@media (width >= 1024px) and (width <= 1579.98px) {
  .software__statistics {
    height: 47.5rem;
  }
}

@media (width <= 1023.98px) {
  .software__statistics {
    padding: 2.5rem 0 3.75rem;
  }
}

.software__statistics-box {
  border-bottom: .4375rem solid var(--landing-accent-color);
  padding: 2.5rem 1.875rem;
  box-shadow: 0 0 .625rem #0000000d;
}

@media (width >= 1580px) {
  .software__statistics-box {
    min-height: 21.5rem;
  }
}

.software__statistics-amount {
  font-size: calc(1.475rem + 2.05063vw);
}

@media (width >= 1580px) {
  .software__statistics-amount {
    font-size: 3.5rem;
  }
}

@media (width >= 1024px) {
  .software__statistics-amount {
    padding-top: 2.375rem;
  }
}

.software__statistics-description {
  color: var(--landing-color-dark-gray);
  font-size: calc(1.2625rem + .113924vw);
}

@media (width >= 1580px) {
  .software__statistics-description {
    font-size: 1.375rem;
  }
}

@media (width >= 1024px) {
  .software__statistics-description {
    margin-top: 1.125rem;
  }
}

@media (width <= 1023.98px) {
  .software__statistics-description {
    margin-top: .5rem;
  }
}

.software__map {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 1580px) {
  .software__map {
    min-width: 131.25rem;
  }
}

@media (width >= 1024px) {
  .partners__list {
    margin-top: 6.25rem;
    padding-bottom: 9rem;
  }
}

@media (width <= 1023.98px) {
  .partners__list {
    margin-top: 3.125rem;
    padding-bottom: 5rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

@media (width >= 1024px) {
  .partners__list .page__carousel-slide {
    flex-basis: 16.6667%;
  }
}

@media (width >= 768px) and (width <= 1023.98px) {
  .partners__list .page__carousel-slide {
    flex-basis: 33.3333%;
  }
}

@media (width >= 375px) and (width <= 767.98px) {
  .partners__list .page__carousel-slide {
    flex-basis: 50%;
  }
}

.partners__list:after {
  content: "";
  background: linear-gradient(to right, transparent, rgba(var(--landing-accent-color-rgb), 1), transparent);
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.partners__list-controls {
  pointer-events: none;
  margin-left: -3.125rem;
  margin-right: -3.125rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.partners__list-control {
  pointer-events: auto;
  width: 2.5rem;
  height: 100%;
}

@media (width >= 1024px) {
  .advantages {
    padding-top: 7.5rem;
    padding-bottom: 11.5625rem;
  }
}

@media (width <= 1023.98px) {
  .advantages {
    padding-top: 3.75rem;
    padding-bottom: 4.375rem;
  }
}

@media (width >= 1024px) {
  .advantages__list {
    padding-top: 8rem;
  }
}

@media (width <= 1023.98px) {
  .advantages__list {
    padding-top: 2.5rem;
  }
}

.advantages__box {
  background-color: #f3f4f6;
}

@media (width >= 1580px) {
  .advantages__box {
    min-height: 26.875rem;
  }
}

@media (width >= 1024px) {
  .advantages__box {
    padding: 2.1875rem 1.75rem;
  }

  .advantages__box .svg-icon {
    width: 5.625rem;
    height: 5.625rem;
  }
}

@media (width <= 1023.98px) {
  .advantages__box {
    padding: 1.5625rem;
  }

  .advantages__box .svg-icon {
    width: 3.75rem;
    height: 3.75rem;
  }
}

@media (width >= 1024px) {
  .advantages__description {
    margin-top: 2.8125rem;
  }
}

.roadmap {
  overflow: hidden;
}

.roadmap.is-animated .roadmap__list:after {
  opacity: 1;
}

.roadmap.is-animated .roadmap__planet {
  opacity: 1;
  transform: translateY(0);
}

@media (width >= 1024px) {
  .roadmap {
    padding-top: 5.125rem;
  }
}

@media (width <= 1023.98px) {
  .roadmap {
    padding-top: 3.75rem;
  }
}

.roadmap__planet {
  text-align: center;
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  transform: translateY(50px);
}

@media (width >= 1580px) {
  .roadmap__planet {
    height: 33.5rem;
  }
}

.roadmap__list {
  position: relative;
}

@media (width >= 1024px) {
  .roadmap__list {
    margin-top: 7.1875rem;
  }
}

@media (width <= 1023.98px) {
  .roadmap__list {
    margin-top: 3.125rem;
  }
}

@media (width <= 575.98px) {
  .roadmap__list {
    margin-left: var(--page-gutter-x);
  }
}

.roadmap__list:after {
  content: "";
  opacity: 0;
  background: linear-gradient(#fff, #0000);
  width: 3px;
  height: 100%;
  transition: opacity .2s ease-in;
  display: block;
  position: absolute;
  top: 1.725rem;
}

@media (width >= 576px) {
  .roadmap__list:after {
    left: calc(50% - 1px);
  }
}

@media (width <= 575.98px) {
  .roadmap__list:after {
    left: -1px;
  }
}

@media (width >= 1580px) {
  .roadmap__item + .roadmap__item {
    padding-top: 13.75rem;
  }
}

@media (width >= 1024px) and (width <= 1579.98px) {
  .roadmap__item + .roadmap__item {
    padding-top: 10rem;
  }
}

@media (width >= 576px) and (width <= 1023.98px) {
  .roadmap__item + .roadmap__item {
    padding-top: 6.25rem;
  }
}

@media (width <= 575.98px) {
  .roadmap__item + .roadmap__item {
    padding-top: 3.125rem;
  }
}

.roadmap__item-years {
  font-size: calc(1.31875rem + .626582vw);
}

@media (width >= 1580px) {
  .roadmap__item-years {
    font-size: 1.9375rem;
  }
}

@media (width >= 576px) {
  .roadmap__item-years {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 2.75rem;
  }
}

.roadmap__item-description {
  padding-left: 2.75rem;
  position: relative;
}

@media (width >= 576px) {
  .roadmap__item-description {
    max-width: 27rem;
  }
}

.roadmap__item-description-content {
  opacity: .8;
}

.roadmap__item-description:after, .roadmap__item-description:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
}

.roadmap__item-description:before {
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  top: .5625rem;
  left: -.75rem;
}

.roadmap__item-description:after {
  background-color: #0000;
  border: 3px solid #000;
  width: .75rem;
  height: .75rem;
  top: .9375rem;
  left: -.375rem;
}

.feedback {
  font-size: calc(1.2625rem + .113924vw);
}

@media (width >= 1580px) {
  .feedback {
    font-size: 1.375rem;
  }
}

@media (width >= 1024px) {
  .feedback {
    padding-top: 6.25rem;
    padding-bottom: 8.75rem;
  }
}

@media (width <= 1023.98px) {
  .feedback {
    padding-top: 3.75rem;
    padding-bottom: 5rem;
  }
}

.feedback__email:hover, .feedback__link:hover {
  text-decoration: underline;
}

.dropdown-menu {
  --page-dropdown-padding-y: 0;
  --page-dropdown-font-size: 1rem;
}

.page__underlined {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, var(--landing-accent-color), var(--landing-accent-color));
  background-position-x: 0;
  background-position-y: calc(100% - var(--landing-underline-offset-y) * -1);
  background-size: 100% calc(var(--landing-underline-height) * 1px), auto calc(var(--landing-underline-height) * 1px), auto calc(var(--landing-underline-height) * 1px);
  display: inline;
}

.page__title {
  letter-spacing: .02em;
  font-size: calc(1.375rem + 1.13924vw);
  font-weight: 700;
  line-height: 1.35;
}

@media (width >= 1580px) {
  .page__title {
    font-size: 2.5rem;
  }
}

.page__color-primary, .page__bg-primary {
  color: var(--landing-accent-color);
}

.page__color-gray, .page__bg-gray {
  color: var(--landing-color-gray);
}

.page__color-gray-dark, .page__bg-gray-dark {
  color: var(--landing-color-dark-gray);
}

.page__carousel {
  overflow: hidden;
}

.page__carousel-container {
  display: flex;
}

.page__carousel-slide {
  flex: 0 0 100%;
  min-width: 0;
}

[data-animation-target] {
  opacity: 0;
  visibility: hidden;
}
/*# sourceMappingURL=index.3951cb89.css.map */
