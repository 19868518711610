.partners {
    $control-size: rem(40px);

    &__list {
        @include media-breakpoint-up(lg) {
            margin-top: rem(100px);
            padding-bottom: rem(144px);
        }
        @include media-breakpoint-down(lg) {
            margin-top: rem(50px);
            padding-bottom: rem(80px);

            padding-left: $control-size * 1.25;
            padding-right: $control-size * 1.25;
        }
        .page__carousel {
            &-slide {

                @include media-breakpoint-up(lg) {
                    flex-basis: calc(100% / 6);
                }
                @include media-breakpoint-between(md, lg) {
                    flex-basis: calc(100% / 3);
                }
                @include media-breakpoint-between(m, md) {
                    flex-basis: calc(100% / 2);
                }
            }
        }
        &::after {
            @include pseudo;
            height: $border-width;
            background: linear-gradient(
                to right,
                transparent,
                rgba(var(--landing-accent-color-rgb), 1),
                transparent
            );
            bottom: 0;
            left: 0;
            width: 100%;
        }
        &-controls {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            pointer-events: none;
            margin-left: - $control-size * 1.25;
            margin-right: - $control-size * 1.25;
        }
        &-control {
            pointer-events: auto;
            width: $control-size;
            height: 100%;
        }
    }
}