@use 'sass:math';

.roadmap {
    $square-size: rem(24px);
    $square-offset: rem(32px);
    $square-top-offset: rem(9px);
    overflow: hidden;
    &.is-animated {
        .roadmap {
            &__list::after {
                opacity: 1;
            }
            &__planet {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem(82px);
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(60px);
    }
    &__planet {
        transition-property: opacity, transform;
        transition-duration: #{$transition-duration * 5}s;
        transition-timing-function: ease-in-out;
        text-align: center;
        transform: translateY(50px);
        opacity: 0;
        @include media-breakpoint-up(xl) {
            height: rem(536px);
        }
    }
    &__list {
        position: relative;
        @include media-breakpoint-up(lg) {
            margin-top: rem(115px);            
        }
        @include media-breakpoint-down(lg) {
            margin-top: rem(50px);            
        }
        @include media-breakpoint-down(sm) {
            margin-left: var(--#{$variable-prefix}gutter-x);
        }
        &::after {
            @include pseudo;
            transition: opacity #{$transition-duration}s ease-in;
            top: $square-size * 1.15;
            width: get($border-widths, middle);
            height: 100%;
            background: linear-gradient(to bottom, get($colors, white), transparent);
            opacity: 0;
            @include media-breakpoint-up(sm) {
                left: calc(50% - #{get($border-widths, default)});
            }
            @include media-breakpoint-down(sm) {
                left: - get($border-widths, default);
            }
        }
    }
    &__item {
        & + .roadmap__item {
            @include media-breakpoint-up(xl) {
                padding-top: rem(220px);
            }
            @include media-breakpoint-between(lg, xl) {
                padding-top: rem(160px);
            }
            @include media-breakpoint-between(sm, lg) {
                padding-top: rem(100px);
            }
            @include media-breakpoint-down(sm) {
                padding-top: rem(50px);
            }
        }
        &-years {
            @include font-size(31px);
            @include media-breakpoint-up(sm) {
                max-width: 50%;
                flex: 0 0 50%;
                padding-right: #{$square-offset + math.div($square-size, 2)};
            }
        }
        &-description {
            padding-left: #{$square-offset + math.div($square-size, 2)};
            position: relative;
            @include media-breakpoint-up(sm) {
                max-width: #{rem(400px) + $square-offset};
            }
            &-content {
                opacity: .8;
            }
            &::after, &::before {
                @include pseudo;
                z-index: get($index, default);
            }
            &::before {
                width: $square-size;
                height: $square-size;
                background-color: get($colors, white);
                left: - math.div($square-size, 2);
                top: $square-top-offset;
            }
            &::after {
                width: math.div($square-size, 2);
                height: math.div($square-size, 2);
                background-color: transparent;
                border: get($border-widths, middle) solid get($colors, black);
                left: - math.div($square-size, 4);
                top: math.div($square-size, 4) + $square-top-offset;
            }
        }
    }
}