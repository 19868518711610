.title {
    &-large {
        @include font-size($h1-font-size)
    }
    &-extra-large {
        @include font-size($font-size-base * 2)
    }
    &-default {
        font-weight: 500;
    }
    &-accent {
        font-family: $font-family-accent;
    }
    &-accordion {
        position: relative;
        padding-right: rem(30px);

        &:after {
            @include transition(transform);
            @include triangle('down', rem(11px));
            top: 50%;
            transform: translateY(-50%) rotateX(0deg);
            right: rem(10px);
        }
    }
}

[aria-expanded="false"] {
    .title-accordion:after {
        transform: translateY(-50%) rotateX(-180deg);
    }
}


.text {
    &-small {
        @include font-size($text-font-size-small)
    }
    &-regular {
        @include font-size($text-font-size-regular)
    }
    &-sub-middle {
        @include font-size($text-font-size-middle)
    }
    &-middle {
        @include font-size($text-font-size-large)
    }
    &-large {
        @include font-size($text-font-size-extra-large)
    }
    &-extra-large {
        @include media-breakpoint-up(sm) {
            @include font-size($text-font-size-ultra-large)
        }
        @include media-breakpoint-down(sm) {
            @include font-size($text-font-size-large)
        }
    }
    &-gilroy {
        font-family: $font-family-accent;
    }
    &-italic {
        font-style: italic;
    }
    &-light {
        font-weight: 300;
    }
    &-normal {
        font-weight: 400;
    }
    &-medium {
        font-weight: 500;
    }
    &-semibold {
        font-weight: 600;
    }
    &-bold {
        font-weight: 700;
    }
    &-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &-clamp {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &-two {
            -webkit-line-clamp: 2;
        }
        &-three {
            -webkit-line-clamp: 3;
        }
        &-four {
            -webkit-line-clamp: 4;
        }
        &-five {
            -webkit-line-clamp: 5;
        }
    }
    &-indent {
        text-indent: $spacer;
    }
    &-underlined {
        display: inline;
        box-decoration-break: clone;
        background-repeat: no-repeat;
        background-image:
            linear-gradient(180deg, var(--landing-accent-color), var(--landing-accent-color));
        background-position-x: 0;
        background-position-y: calc(100% - var(--landing-underline-offset-y) * -1);
        background-size:
            100% calc(var(--landing-underline-height) * 1px),
            auto calc(var(--landing-underline-height) * 1px),
            auto calc(var(--landing-underline-height) * 1px);
    }
}

