.button {
    transition-property: background-color, color;
    transition-duration: #{$transition-duration}s;
    text-transform: uppercase;
    background-color: var(--landing-accent-color);
    display: inline-block;
    font-weight: 600;
    color: get($colors, white);
    position: relative;
    &.is-loading {
        pointer-events: none;
        .landing__button {
            &-content {
                opacity: 0;
                visibility: hidden;
            }
            &-loader {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        @include font-size($text-font-size-ultra-large);
        padding: rem(15px) rem(31px) rem(13px);
    }
    @include media-breakpoint-down(lg) {
        @include font-size($text-font-size-middle);
        padding: rem(12px) rem(28px) rem(10px);
    }
    &:hover {
        color: get($colors, white);
        background-color: var(--landing-accent-color-darken);
    }
    &-loader {
        @include center('both');
        opacity: 0;
        visibility: hidden;
    }
}
