.clubs {
    background-color: var(--landing-bg-dark);
    @include media-breakpoint-up(lg) {
        padding-top: rem(90px);
        padding-bottom: rem(237px);
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(50px);
        padding-bottom: rem(80px);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__effect {
        @include media-breakpoint-up(lg) {
            padding-top: rem(190px);
        }
        @include media-breakpoint-down(lg) {
            padding-top: rem(50px);
        }
        .svg-icon {
            width: .63em;
            height: .69em;
        }
        &-graph {
            @include font-size(136px);
        }
        &-percent {
            line-height: 1;
            &-symbol {
                font-weight: 500;
            }
            &-value {
                font-weight: 800;
            }
        }
        &-description {
            line-height: 1.25;
            &-accent {
                @include font-size(41px);
            }
        }
    }
}