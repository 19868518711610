ul.list-default {
    padding-left: rem(22px);
    li {
        list-style: disc;
    }
}
ol {
    counter-reset: item;
    & > li {
        display: block;
        &:before {
            content: counters(item, ".") ". ";
            counter-increment: item;
        }
        & + li {
            margin-top: rem(8px);
        }
    }
}