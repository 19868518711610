@import 'utilities/variables';
@import 'utilities/fonts';

:root {
    --landing-accent-color: #DC0C15;
    --landing-accent-color-rgb: 220, 12, 21;
    --landing-bg-dark: #151515;
    --landing-accent-color-darken: color-mix(in srgb,var(--landing-accent-color),#000 15%);
    --landing-section-offset-y: #{rem(90px)};
    --landing-underline-height: 6;
    --landing-underline-offset-y: -1px;
    --landing-color-gray: #B7B7B7;
    --landing-color-dark-gray: #666666;
}

@include media-breakpoint-down(lg) {
    :root {
        --landing-section-offset-y: #{rem(50px)};
    }
}

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/transitions';

@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/spinners';
@import 'node_modules/bootstrap/scss/helpers/stretched-link';
@import 'node_modules/bootstrap/scss/helpers/visually-hidden';
@import 'node_modules/bootstrap/scss/helpers/ratio';

$utilities: map-merge(
        $utilities,
        (
            "align": null,
            "shadow": null,
            "top": null,
            "bottom": null,
            "start": null,
            "end": null,
            "translate-middle": null,
            "border": map-get($utilities, "border"),
            "border-top": null,
            "border-end": null,
            "border-bottom": null,
            "border-start": null,
            "border-color": null,
            "border-width": null,
            "max-width": (
                property: max-width,
                class: mw,
                values: (25: 25%, 50: 50%, 75: 75%, 100: 100%)
            ),
            "viewport-width": null,
            "min-viewport-width": null,
            "max-height": null,
            "viewport-height": null,
            "min-viewport-height": null,
            "gap": null,
            "font-size": null,
            "font-style": null,
            "font-weight": null,
            "white-space": null,
            "word-wrap": null,
            "color": map-get($utilities, "color"),
            "gradient": null,
            "rounded": map-get($utilities, "rounded"),
            "rounded-top": null,
            "rounded-end": null,
            "rounded-bottom": null,
            "rounded-start": null,
            "font-family": null,
            "display": map-merge(
                    map-get($utilities, "display"),
                    (
                        print: false,
                        values: inline inline-block block flex inline-flex none
                    )
            ),
            "align-items": map-merge(
                    map-get($utilities, "align-items"),
                    (
                        values: (
                            start: flex-start,
                            end: flex-end,
                            center: center
                        )
                    )
            ),
            "align-content": map-merge(
                    map-get($utilities, "align-content"),
                    (
                        values: (
                            start: flex-start,
                            end: flex-end,
                            center: center,
                            between: space-between,
                            around: space-around,
                        )
                    )
            ),
            "align-self": map-merge(
                    map-get($utilities, "align-self"),
                    (
                        values: (
                            start: flex-start,
                            end: flex-end,
                            center: center
                        )
                    )
            ),
            "padding-top": map-get($utilities, "padding-top"),
            "padding-bottom": map-get($utilities, "padding-bottom"),
            "margin-top": map-get($utilities, "margin-top"),
            "margin-bottom": map-get($utilities, "margin-bottom")
        )
);

@import 'node_modules/bootstrap/scss/utilities/api';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'base/root';
@import 'base/reboot';
@import 'components/header';
@import 'components/footer';
@import 'components/button';
@import 'components/typography';
@import 'components/list.scss';
@import 'sections/intro';
@import 'sections/description';
@import 'sections/clubs';
@import 'sections/software';
@import 'sections/partners';
@import 'sections/advantages';
@import 'sections/roadmap';
@import 'sections/feedback';

.dropdown-menu {
    --#{$variable-prefix}dropdown-padding-y: 0;
    --#{$variable-prefix}dropdown-font-size: 1rem;
}

.page {
    &__underlined {
        display: inline;
        box-decoration-break: clone;
        background-repeat: no-repeat;
        background-image:
            linear-gradient(180deg, var(--landing-accent-color), var(--landing-accent-color));
        background-position-x: 0;
        background-position-y: calc(100% - var(--landing-underline-offset-y) * -1);
        background-size:
            100% calc(var(--landing-underline-height) * 1px),
            auto calc(var(--landing-underline-height) * 1px),
            auto calc(var(--landing-underline-height) * 1px);
    }
    &__title {
        @include font-size(40px);
        font-weight: 700;
        letter-spacing: .02em;
        line-height: $line-height-base;
    }
    &__color, &__bg {
        &-primary {
            color: var(--landing-accent-color)
        }
        &-gray {
            color: var(--landing-color-gray);
            &-dark {
                color: var(--landing-color-dark-gray);
            }
        }
    }
    &__carousel {
        overflow: hidden;
        &-container {
            display: flex;
        }
        &-slide {
            flex: 0 0 100%;
            min-width: 0;
        }
    }
}

[data-animation-target] {
    opacity: 0;
    visibility: hidden;
}
