.feedback {
    @include font-size(22px);
    @include media-breakpoint-up(lg) {
        padding-top: rem(100px);
        padding-bottom: rem(140px);        
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(60px);
        padding-bottom: rem(80px);        
    }
    &__email {
        &:hover {
            text-decoration: underline;
        }
    }
    &__link {
        &:hover {
            text-decoration: underline;
        }
    }
}