@import '../utilities/variables';

:root {
	@each $color, $value in $colors {
		--#{$variable-prefix+$color}: #{$value};
	}

	@each $color, $value in $theme-colors-rgb {
		--#{$variable-prefix+$color}-rgb: #{$value};
	}

	@if $font-size-root != null {
		font-size: var(--#{$variable-prefix}-root-font-size);
	}

	@if $enable-smooth-scroll {
		@media (prefers-reduced-motion: no-preference) {
			scroll-behavior: smooth;
		}
	}
}

.page {
	@include media-breakpoint-up(lg) {
        height: 100%;
    }
    &__main {
        @include media-breakpoint-up(lg) {
            padding-top: rem(50px);
            padding-bottom: rem(170px);
        }
        @include media-breakpoint-down(lg) {
            padding-top: rem(30px);
            padding-bottom: rem(80px);
        }
    }
	&__wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100%;
        position: relative;
		.footer {
			flex: 0 0 auto;
		}
	}
	&__content {
		flex: 1 0 auto;
		overflow: hidden;
	}
}
