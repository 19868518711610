.footer {
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        padding-top: rem(75px);
        padding-bottom: rem(60px);
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(70px);
        padding-bottom: rem(25px);
    }
    &__copyright {
        padding-top: rem(60px);
        &-text {
            @include media-breakpoint-up(sm) {
                $dash-size: get($border-widths, small);
                $dash-offset-size: rem(23px);

                @include font-size(17px);
                position: relative;
                padding-left: calc(#{$dash-offset-size * 2} + #{$dash-size});
                &:before {
                    @include pseudo;
                    width: $dash-size;
                    height: rem(34px);
                    background-color: #3F3F3F;
                    top: 50%;
                    transform: translateY(-50%);
                    left: $dash-offset-size;
                }
            }
        }
        .svg-icon {
            @include media-breakpoint-up(sm) {
                width: rem(58px);
                height: rem(58px);
            }
            @include media-breakpoint-down(sm) {
                width: rem(35px);
                height: rem(35px);
            }
        }
    }
    &__logotype {
        max-width: rem(260px);
    }
}