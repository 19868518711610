@use "sass:math";

@mixin pseudo($display: block, $pos: absolute, $content: ''){
	content: $content;
	display: $display;
	position: $pos;
}
/*---------------pseudo element default styles---------------*/

@mixin pseudo-wrapper($width: 100%, $height: 100%, $content: '') {
	content: $content;
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	width: $width;
	height: $height;
}
/*---------------------pseudo full size---------------------*/

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote(  math.div($y, $x)  * 100 + '%' );
	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}
/*--------------------proportional ratio--------------------*/

@mixin placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}
/*--------------------placeholder styles--------------------*/

@mixin transition($what: all, $when: #{$transition-duration}s, $how: ease) {
	transition: $what $when $how;
}
/*------------------------transition------------------------*/

@mixin background($path, $position: center center, $repeat: no-repeat, $size: cover) {
	background: {
		image: url($path);
		position: $position;
		repeat: $repeat;
		size: $size;
	}
}
/*--------------------background image--------------------*/

@mixin center($position) {
	position: absolute;

	@if $position == 'vertical' {
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	@else if $position == 'horizontal' {
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translate(-50%);
	}
	@else if $position == 'both' {
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}
/*--------------------absolute alignment--------------------*/

@mixin triangle($direction, $size: 6px, $color: get($colors, black), $multiplier: .5) {
	content: '';
	display: block;
	position: absolute;
	height: 0; 
	width: 0;
	@if ($direction == 'up'){
		border-bottom: $size*$multiplier solid $color;
		border-left: 1*0.5*$size solid transparent;
		border-right: 1*0.5*$size solid transparent;
	}
	@else if ($direction == 'down'){
		border-top: $size*$multiplier solid $color;
		border-left: 1*0.5*$size solid transparent;
		border-right: 1*0.5*$size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: 1*0.5*$size solid transparent;
		border-bottom: 1*0.5*$size solid transparent;
		border-right: $size*$multiplier solid $color;
	}
	@else if ($direction == 'right'){
		border-top: 1*0.5*$size solid transparent;
		border-bottom: 1*0.5*$size solid transparent;
		border-left: $size*$multiplier solid $color;
	}
}
/*------------------make triangle------------------*/

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
	@font-face {
        font-family: quote($font-name);
        src: url($file-name + '.woff2') format('woff2'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf')  format('truetype');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
	}
}
/*---------------------------fonts---------------------------*/

@function get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}
/*-----------------------map get recursive-----------------------*/

@mixin interaction($type) {
	@if $type == 'mouse' {
		@media (pointer: fine) and (any-hover: hover), (min-width:0\0) {
			@content
		}
		/*
 			pointer: fine - Desktop/Laptop and Desktop/Laptop+Touchscreen
 			any-pointer: fine - Touchscreen+Mouse and Desktop/Laptop and Touchscreen and Desktop/Laptop+Touchscreen
		*/
	} @else if $type == 'touch' {
		@media (pointer: coarse) and (any-pointer: fine) {
			@content
		}
		/*
 			pointer: coarse - Touchscreen and Touchscreen+Mouse
 			any-pointer: fine - Touchscreen+Mouse and Desktop/Laptop and Touchscreen and Desktop/Laptop+Touchscreen
		*/
	}
}
/*-----------------------interaction support-----------------------*/
