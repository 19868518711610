.software {
    @include media-breakpoint-up(lg) {
        padding-top: rem(100px);
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(60px);
    }
    &__statistics {
        @include media-breakpoint-up(xl) {
            height: rem(1030px);
        }
        @include media-breakpoint-between(lg, xl) {
            height: rem(760px);
        }
        @include media-breakpoint-down(lg) {
            padding: rem(40px) 0 rem(60px);
        }
        &-box {
            border-bottom: rem(7px) solid var(--landing-accent-color);
            padding: rem(40px) rem(30px);
            box-shadow: 0 0 rem(10px) 0 rgba(get($colors, black), .05);
            @include media-breakpoint-up(xl) {
                min-height: rem(344px)
            }
        }
        &-amount {
            @include font-size(56px);
            @include media-breakpoint-up(lg) {
                padding-top: rem(38px);
            }
        }
        &-description {
            @include font-size(22px);
            color: var(--landing-color-dark-gray);            
            @include media-breakpoint-up(lg) {
                margin-top: rem(18px);
            }
            @include media-breakpoint-down(lg) {
                margin-top: rem(8px);
            }
        }
    }
    &__map {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        @include media-breakpoint-up(xl) {
            min-width: rem(2100px);
        }
    }
}