@use 'sass:math';

.header {
    transition-duration: #{$transition-duration}s;
    transition-timing-function: ease-in-out;
    transition-property: transform, background-color, padding-top, padding-bottom;
    position: fixed;
    z-index: get($index, md);
    left: 0;
    right: 0;
    top: 0;
    &__logotype {
        width: rem(212px);
        
    }
    &.header {
        &-pinned {
            transform: translateY(0%);
        }
        &-not-top {
            background-color: rgba(get($colors, black), .5);
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
        &-unpinned {
            transform: translateY(-100%);
        }
    }
    &__menu {
        .dropdown {
            &-menu {
                --#{$variable-prefix}dropdown-font-size: #{rem(18px)};

                @include media-breakpoint-up(xl) {
                    position: relative;
                    display: block !important;
                }
                @include media-breakpoint-down(xl) {
                    text-align: right;
                    min-width: initial;
                    white-space: nowrap;
                    &:before {
                        @include triangle('up', $spacer * 1.25, get($colors, white));
                        bottom: calc(99% - #{$spacer});
                        right: math.div($spacer, 2);
                    }
                }
            }
        }
    }
    &__nav {
        $nav-x-padding: 1.3vw;

        user-select: none;
        @include media-breakpoint-down(xl) {
            background-color: get($colors, white);
            color: get($colors, black);
            font-weight: 700;
            padding: math.div($grid-gutter-width, 1.5) $grid-gutter-width;
            margin-top: $spacer;
            box-shadow: 0 0 $spacer 0 rgba(get($colors, black), .15);
        }
        &-link {
            @include transition(color);
            display: inline-block;
            position: relative;
            @include media-breakpoint-up(lg) {
                color: get($colors, white);
                padding: $spacer 0;
                & + .header__nav-link {
                    margin-left: 2.6vw;
                }
            }
            @include media-breakpoint-down(lg) {
                padding: math.div($spacer, 2) 0;
            }
            &:before {
                @include pseudo;
                transition-duration: #{$transition-duration}s;
                transition-property: left, width;
                transition-timing-function: ease-out;
                width: 0;
                height: get($border-widths, large);
                left: 50%;
                background-color: var(--landing-accent-color);
                @include media-breakpoint-up(lg) {
                    bottom: $spacer * 1.33;
                }
                @include media-breakpoint-down(lg) {
                    bottom: $spacer * .85;
                }
            }
            &:hover, &.is-highlighted {
                @include media-breakpoint-up(lg) {
                    color: get($colors, white);
                }
                &:before {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
}